import Navbar from "../components/Navbar";
import { Card, Tooltip } from "flowbite-react";
import Carousel  from "../components/Carousel";
import Heading from "../components/Heading";
import Info from "../components/Info";
import Cards from "../components/Cards"
import Testimonial from "../components/Testimonial";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import OurMotive from "../components/OurMotive";
import About from "../components/About";
import DailyDarshan from "../components/DailyDarshan";
import { useEffect, useState } from "react";
import WhyUs from "../components/WhyUs";
import PackageDisplay from "../components/PackageDisplay";
import { Helmet } from "react-helmet";
export default function Home() {
  const [expanded, setExpanded] = useState(false);
  const scrollToCards = () => {
    const cardsSection = document.getElementById("cards");
    if (cardsSection) {
      cardsSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
      <>
            {/* <Navbar /> */}
           {/* <DailyDarshan/> */}
           <Heading/>
        {/* <div className="md:mr-10 md:ml-10 mt-2 mr-2 ml-2" > */}
        <Helmet>
          <title>Ujjain Darshan Tour Packages | Best packages for Ujjain</title>
          <meta name="description" content="Experience Ujjain with Ease | Ujjainonwheels.in" />
        </Helmet>
           <OurMotive/>
           <Carousel />
           <Info/>
           <WhyUs/>
           <div id="cards">
           <PackageDisplay/>
           {/* <Cards/> */}
           </div>
           <FAQ/>
           <Testimonial/>
           <About/>
           <Footer/>
        {/* </div> */}
<div className="fixed bottom-0 right-0 mb-4 mr-4 flex flex-row">
  <div className={`absolute bottom-0 right-24 mb-16   expand ${expanded ? 'expand-visible' : 'expand-hidden'}`}>
    {/* <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full mb-2" onClick={() => window.open("https://wa.me/your-phone-number", "_blank")}> */}
    <img onClick={() => window.open("https://wa.me/+919977886853", "_blank")} width="48" height="48" src="https://img.icons8.com/color/48/whatsapp--v1.png" alt="whatsapp--v1"/>
    {/* </button> */}
    {/* <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => window.open("tel:your-phone-number")}> */}
    
    <img onClick={() => window.open("tel:9977886853")} width="50" height="50" src="https://img.icons8.com/ios/50/phone--v1.png" alt="phone--v1"/>
   
    {/* </button> */}
  </div>
  <button className="bg-[#FF8303] hover:[#FE6E00] text-white text-2xl font-bold py-2 mb-3 mr-3 px-4 rounded-full" onClick={() => setExpanded(!expanded)}>
    +
  </button>
  {/* <Tooltip className="text-xs text-white" content="Go to Packages" placement="left"> */}
  <button className="bg-[#FF8303] hover:bg-[#FE6E00] text-white text-xs font-bold py-4 mb-3 px-4 rounded-full"
          onClick={scrollToCards}
        >
          {/* <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white"><path d="M480-384 288-576h384L480-384Z"/></svg> */}
          Packages
        </button>
        {/* </Tooltip> */}
</div>
      </>
    );
}