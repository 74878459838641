import { useEffect, useState } from "react";
import { gsap, Power3 } from "gsap";
import { contentData } from "../data/CarouselData";
export default function Carousels() {
  const [currentContent, setCurrentContent] = useState(contentData[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  let intervalId: NodeJS.Timeout;

  useEffect(() => {
    const section = document.querySelector(".carousel-container-text > div");
    const carousel = document.querySelector(".carousel-container-img");
    
    startInterval();

    return () => {
      clearInterval(intervalId);
      carousel?.removeEventListener('click', handleSlideChange as unknown as EventListener);
    };
  }, [currentIndex]);
  const startInterval = () => {
      
    
    intervalId = setInterval(() => {

     const nextIndex = (currentIndex + 1) % contentData.length;
     handleSlideChange(1);
   }, 9000); // Change content every 9 seconds
   }

   const handleSeeMoreClick = () => {
     clearInterval(intervalId);  
     setIsPopupOpen(true); 
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    startInterval();
  };

   // Pause the interval on hover
  const handleMouseEnter = () => {
    clearInterval(intervalId); // Pause the interval
    setIsHovering(true);
  };

  // Resume the interval on mouse leave
  const handleMouseLeave = () => {
    if(isPopupOpen) return;
    setIsHovering(false);
    startInterval(); // Restart the interval if not already running
  };
const handleSlideChange =  (direction: 1 | -1) => { 
  clearInterval(intervalId);  
  
  gsap.to('.carousel-container-text > div', {
    opacity: 0,
    duration: 1,
    ease: 'power3.in'
  });
  gsap.to('.carousel-container-text > div', {
    duration: 1,
    ease: 'power3.in',
    onComplete: () => {
      const nextIndex = (currentIndex + direction + contentData.length) % contentData.length;
      setCurrentContent(contentData[nextIndex]);
      setCurrentIndex(nextIndex);
      gsap.set('.carousel-container-text > div', { x: '0%', opacity: 0 });
      gsap.to('.carousel-container-text > div', { x: '0%', opacity: 1, duration: 1, ease: 'power3.out' });
    }
  } );
  // startInterval();
};
useEffect(() => {
  if (isPopupOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
}, [isPopupOpen]);
  useEffect(() => {
    const section = document.querySelector('.carousel-container-text > div');
    gsap.from(section, { opacity: 0, duration: 2, ease: 'power3.in' });
    gsap.to(section, { opacity: 1, duration: 2, ease: 'power3.out' });
  }, [currentContent]);

  return (
    <div className="bg-gradient-to-b from-[#fff5eb] to-white  bg-blend-multiply bg-opacity-90 w-full ">
    <div className="carousel-container-text min-h-80 md:mx-6 mx-2 " onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
      <div className="min-h-80">
        <h1 className="text-2xl font-bold break-words mt-4 text-orange-500 tracking-wide bodoni-libre">
          {currentContent.title}
          <br />
        </h1>
        <br />
        <p className="max-w-[800px] text-muted-foreground md:text-xl raleway" data-id="17">
       {currentContent.description.split(" ").slice(0, 50).join(" ")}
       {currentContent.description.split(" ").length > 150 && " ..."}
       {currentContent.description.split(" ").length > 150 && (
         <span className="cursor-pointer text-[#FF8303] hover:text-[#FD5602]" onClick={handleSeeMoreClick}>
           See more
         </span>
       )}
</p>
      </div>
      <div
        className="h-auto sm:h-64 xl:h-80 2xl:h-96 w-full sm:w-96 xl:w-80 2xl:w-96 mt-5 carousel-container-img"
      >
        <div className="carousel sm:w-96 xl:w-80 2xl:w-96 h-full sm:h-64 xl:h-80 2xl:h-96">
          <img className="w-full h-full" src={currentContent.imageUrl} alt={currentContent.alt} />
        </div>
      </div>
    </div>
       <div className="flex justify-center gap-3 mt-2 mb-4">
         <button className="hover:bg-gray-400 text-gray-800 font-bold py-4 px-4 rounded-full transition-colors duration-300" onClick={() => handleSlideChange(-1)}>
           <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343">
             <path d="m330-444 201 201-51 51-288-288 288-288 51 51-201 201h438v72H330Z"/>
           </svg>
         </button>
         <button className=" hover:bg-gray-400 text-gray-800 font-bold py-4 px-4 rounded-full transition-colors duration-300" onClick={() => handleSlideChange(1)}>
           <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343">
             <path d="M630-444H192v-72h438L429-717l51-51 288 288-288 288-51-51 201-201Z"/>
           </svg>
         </button>
       </div>
       {isPopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full scroll-smooth bg-gray-800 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-4 shadow-md max-w-md">
            <h3 className="text-xl font-bold mb-2">
              {currentContent.title}
            </h3>
            <p className="text-gray-700">
              {currentContent.description}
            </p>
            <button className="text-[#FF8303] hover:bg-[#FD5602]  font-bold py-2 px-4 rounded-full mt-4" onClick={handlePopupClose}>
              Close
            </button>
          </div>
        </div>
      )}
        </div>
  );
}