import { Button, Checkbox, Modal , Label, TextInput, Textarea } from "flowbite-react";
import { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import { useNavigate  } from "react-router-dom"; // Import useHistory from react-router-dom
import { Helmet } from "react-helmet";
export default function ContactUS() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [query, setquery] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async(e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const formData = {
      name,
      // email,
      phone,
      query,
    };
    // console.log(formData.email);
    // return;
    const response = await axios.post('https://www.ujjainonwheels.in/api/api/query', { name: formData.name, phone: formData.phone, query: formData.query })
      .then((response) => { 
        setOpenModal(true);
        // Handle success response
      })
      .catch((error) => {
        console.error(error);
        // Handle error response
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex justify-center py-20 md:py-10 h-screen ">
      <Helmet>
        <title>Contact Us - Ujjain on Wheels</title>
        <meta name="description" content="Contact Us - Ujjain on Wheels" />
      </Helmet>
      <form className="flex flex-col gap-4 w-11/12 pt-8 md:w-1/2" onSubmit={handleSubmit}>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="name" value="Your Name" />
          </div>
          <TextInput id="name" type="text" placeholder="Your Name" required value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        {/* <div>
          <div className="mb-2 block">
            <Label htmlFor="email1" value="Your email" />
          </div>
          <TextInput id="email1" type="email" placeholder="Your Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
        </div> */}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="phone" value="Your Mobile Number" />
          </div>
          <TextInput id="phone" type="tel" pattern="[0-9]{10}" placeholder="Your Mobile Number" required value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
        {/* <div>
          <Label htmlFor="date" value="Your Expected date of Arrival" />
          <Datepicker id="date" minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1)} maxDate={new Date(new Date().getFullYear(), new Date().getMonth()+6, new Date().getDate())} title='Select Date of Arrival' onSelectedDateChanged={(date) => setSelectedDate(date)} />
        </div> */}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="query" value="Your query" />
          </div>
          <Textarea id="query" placeholder="Your Query" value={query} onChange={(e) => setquery(e.target.value)} />
        </div>
        <div className="mt-5 flex justify-end">
          <Button type="submit" className="text-white bg-[#FE6E00]">Submit</Button>
        </div>
      </form>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Thank You</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              We have recieved your query. We will get back to you soon.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button onClick={() => navigate("/")} className="text-white bg-[#FE6E00]" >ok</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}