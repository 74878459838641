import { Tooltip } from "flowbite-react";
import { packages1, packages2, packages3, packages4,  } from "../data/SingleDayPackages";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
export default function SinglePackages() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section id="packages" className="  py-20 md:py-10   sm:snap-x w-full dark:bg-gray-700 dark:text-white bg-gradient-to-b from-[#fff5eb] to-[#fffcf9] bg-blend-multiply bg-opacity-90 ">
      <Helmet>
      <title>Best Family Tour Packages for Ujjain | Pacakges Starting @2499</title>
        <meta name="description" content="Experience the spiritual and cultural richness of Ujjain with our carefully crafted family tour packages. Our expert guides will ensure a seamless and unforgettable pilgrimage for your family. - Ujjain on Wheels" />
      </Helmet>
      <div className="container px-4 md:px-6">
        <div className="space-y-8">
          <div className="text-center">
            <h2 className='text-3xl font-bold tracking-tighter sm:text-4xl bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text bodoni-libre'>
            Sacred Ujjain: A Day of Pilgrimage
            </h2>
            <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
            Single day end to end Ujjain packages (with a spiritually guided Ujjain darshan experience by a localite companion).
            </p>
          </div>
         <div className="text-lg font-semibold text-gray-600 text-center">Packages for 1 Person
         <br/>
         <small > (Premium and Luxury packages include upgradations in Stay, Food & Beverages and Logistics).</small>
         </div>
         <div className="flex flex-wrap md:gap-12  md:py-8 px-8 py-10  justify-center packagesCard"  >
  
            {packages1.map((packageItem) => (
              <div 
              key={packageItem.id} 
              className="bg-alice-blue md:ml-0 ms-5  snap-center min-w-[260px] md:w-1/2 lg:w-1/3 xl:w-1/4  rounded-3xl overflow-hidden shadow-lg transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl cursor-pointer dark:bg-gray-800 dark:text-white-800"
              >
                <Link to={`/single-packages-details/${packageItem.id}`} state={{ packageType: "packages1" }} className="min-w-[260px]  snap-center md:w-1/2 lg:w-1/3 xl:w-1/4 ">
                <img src="/mahakal.jpg" alt={packageItem.name + " Package Image"} className="w-full h-40 object-cover rounded-t-lg group-hover:brightness-50" />
                <div className="p-4 space-y-2">
                  <h3 className="text-xl font-semibold text-center ">{packageItem.name}</h3>
                <div className="grid grid-cols-2 justify-between items-center">
                  <div className="flex items-start gap-2 flex-col">
                  <h3 className="text-xl font-semibold text-[#FF8303]">₹{packageItem.price}</h3>
                  <ul className="flex flex-wrap gap-2 list-none m-0 p-0">
                    {packageItem.amenities.map((amenity, index) => (
                        <li key={index} className="flex items-center mb-2">
                      <Tooltip content={amenity.name} style="light">
                      <span dangerouslySetInnerHTML={{ __html: amenity.icon }} />
      </Tooltip>
                    </li>
                    ))}
                  </ul>
                  </div>
                  <div className="flex items-center flex-col">
                  {/* <h4 className="text-lg font-medium">Amenities</h4> */}
                  <p className="text-lg font-medium">{packageItem.days}</p>
                  </div>
                  </div>
                  <p className="text-[#495057]">{packageItem.description}</p>
                </div>
                </Link>
              </div>
            ))}
          </div>
          
         <div className="text-lg font-semibold text-gray-600 mb-4 text-center">Packages for 2 Persons

         <br/>
         <small className="font-medium" > (Premium and Luxury packages include upgradations in Stay, Food & Beverages and Logistics).</small>
         </div>
         <div className="flex flex-wrap md:gap-12  md:py-8 px-8 py-10  justify-center packagesCard"  >
  
  {packages2.map((packageItem) => (
    <div 
    key={packageItem.id} 
    className="bg-alice-blue sm:ml-4 ms-5 snap-center min-w-[260px] md:w-1/2 lg:w-1/3 xl:w-1/4  rounded-3xl overflow-hidden shadow-lg transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl cursor-pointer dark:bg-gray-800 dark:text-white-800"
    >
      <Link to={`/single-packages-details/${packageItem.id}`} state={{ packageType: "packages2" }} >
      <img src="/mahakal.jpg" alt={packageItem.name + " Package Image"} className="w-full h-40 object-cover rounded-t-lg group-hover:brightness-75" />
      <div className="p-4 space-y-2">
        <h3 className="text-xl font-semibold text-center ">{packageItem.name}</h3>
      <div className="grid grid-cols-2 justify-between items-center">
        <div className="flex items-start gap-2 flex-col">
        <h3 className="text-xl font-semibold text-[#FF8303]">₹{packageItem.price}</h3>
        <ul className="flex flex-wrap gap-2 list-none m-0 p-0">
          {packageItem.amenities.map((amenity, index) => (
              <li key={index} className="flex items-center mb-2">
            <Tooltip content={amenity.name} style="light">
            <span dangerouslySetInnerHTML={{ __html: amenity.icon }} />
</Tooltip>
          </li>
          ))}
        </ul>
        </div>
        <div className="flex items-center flex-col">
        {/* <h4 className="text-lg font-medium">Amenities</h4> */}
        <p className="text-lg font-medium">{packageItem.days}</p>
        </div>
        </div>
        <p className="text-[#495057]">{packageItem.description}</p>
      </div>
      </Link>
    </div>
  ))}
</div>  
          
         <div className="text-lg font-semibold text-gray-600 mb-4 text-center">Packages for 3 Persons
         <br/>
         <small > (Premium and Luxury packages include upgradations in Stay, Food & Beverages and Logistics).</small>
         </div>
         <div className="flex flex-wrap md:gap-12  md:py-8 px-8 py-10  justify-center packagesCard"  >
  
  {packages3.map((packageItem) => (
    <div 
    key={packageItem.id} 
    className="bg-alice-blue sm:ml-4 ms-5 snap-center min-w-[260px] md:w-1/2 lg:w-1/3 xl:w-1/4  rounded-3xl overflow-hidden shadow-lg transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl cursor-pointer dark:bg-gray-800 dark:text-white-800"
    >
      <Link to={`/single-packages-details/${packageItem.id}`} state={{ packageType: "packages3" }} >
      <img src="mahakal.jpg" alt={packageItem.name + " Package Image"} className="w-full h-40 object-cover rounded-t-lg group-hover:brightness-75" />
      <div className="p-4 space-y-2">
        <h3 className="text-xl font-semibold text-center ">{packageItem.name}</h3>
      <div className="grid grid-cols-2 justify-between items-center">
        <div className="flex items-start gap-2 flex-col">
        <h3 className="text-xl font-semibold text-[#FF8303]">₹{packageItem.price}</h3>
        <ul className="flex flex-wrap gap-2 list-none m-0 p-0">
          {packageItem.amenities.map((amenity, index) => (
              <li key={index} className="flex items-center mb-2">
            <Tooltip content={amenity.name} style="light">
            <span dangerouslySetInnerHTML={{ __html: amenity.icon }} />
</Tooltip>
          </li>
          ))}
        </ul>
        </div>
        <div className="flex items-center flex-col">
        {/* <h4 className="text-lg font-medium">Amenities</h4> */}
        <p className="text-lg font-medium">{packageItem.days}</p>
        </div>
        </div>
        <p className="text-[#495057]">{packageItem.description}</p>
      </div>
      </Link>
    </div>
  ))}
</div>
          
         <div className="text-lg font-semibold text-gray-600 mb-4 text-center">Packages for 4 Persons
         <br/>
         <small > (Premium and Luxury packages include upgradations in Stay, Food & Beverages and Logistics).</small>
         </div>
         <div className="flex flex-wrap md:gap-12  md:py-8 px-8 py-10  justify-center packagesCard"  >
  
  {packages4.map((packageItem) => (
    <div 
    key={packageItem.id} 
    className="bg-alice-blue ms-5  snap-center min-w-[260px] md:w-1/2 lg:w-1/3 xl:w-1/4  rounded-3xl overflow-hidden shadow-lg transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl cursor-pointer dark:bg-gray-800 dark:text-white-800"
    >
      <Link to={`/single-packages-details/${packageItem.id}`} state={{ packageType: "packages4" }} >
      <img src="mahakal.jpg" alt={packageItem.name + " Package Image"} className="w-full h-40 object-cover rounded-t-lg group-hover:brightness-75" />
      <div className="p-4 space-y-2">
        <h3 className="text-xl font-semibold text-center ">{packageItem.name}</h3>
      <div className="grid grid-cols-2 justify-between items-center">
        <div className="flex items-start gap-2 flex-col">
        <h3 className="text-xl font-semibold text-[#FF8303]">₹{packageItem.price}</h3>
        <ul className="flex flex-wrap gap-2 list-none m-0 p-0">
          {packageItem.amenities.map((amenity, index) => (
              <li key={index} className="flex items-center mb-2">
            <Tooltip content={amenity.name} style="light">
            <span dangerouslySetInnerHTML={{ __html: amenity.icon }} />
</Tooltip>
          </li>
          ))}
        </ul>
        </div>
        <div className="flex items-center flex-col">
        {/* <h4 className="text-lg font-medium">Amenities</h4> */}
        <p className="text-lg font-medium">{packageItem.days}</p>
        </div>
        </div>
        <p className="text-[#495057]">{packageItem.description}</p>
      </div>
      </Link>
    </div>
  ))}
</div>
          {/* <div className="text-center ">
  <h3 className="text-xl font-semibold text-gray-600 mb-4">
    Traveling 5 or more persons?
  </h3>
  <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
    For groups of 5 or more, we offer customized packages to cater to your specific needs.
    Contact us to discuss your requirements and get a personalized quote.
  </p>
  <div className="flex justify-center gap-4">
    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300">
      Contact Us
    </button>
    <Link to="/contact" className="text-[#FE6E00] text-xl hover:underline">
      Contact Us
    </Link>
  </div>
</div> */}
        </div>
      </div>
    </section>
  );
}