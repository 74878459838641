import { useEffect } from "react";

export default function Tnc() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-white py-20 md:py-10 w-full">

    <div className="max-w-3xl mx-auto p-4 md:p-6 lg:p-8 ">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <div className="space-y-6">
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">1. Introduction</h2>
          <p>
            Welcome to Ujjainonwheels.in . These Terms and Conditions ("TNC" or "Agreement") govern your use of the Website and any services provided through it. By accessing or using the Website, you agree to be bound by these TNC.
          </p>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">2. Definitions</h2>
          <ul className="list-disc pl-4">
            <li>
              "We", "us", and "our" refer to <a className="text-[#FF8303]" href="https://ujjainonwheels.in">ujjainonwheels.in</a>  the owner and operator of the Website.
            </li>
            <li>
              "You" and "your" refer to the individual accessing or using the Website.
            </li>
            <li>
              "Package" refers to any service or product offered on the Website.
            </li>
            <li>
              "Booking" refers to the process of selecting a Package and filling out the required information.
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">3. Use of the Website</h2>
          <ul className="list-disc pl-4">
            <li>
              You agree to use the Website for lawful purposes only and in compliance with all applicable laws and regulations.
            </li>
            <li>
              You agree not to:
              <ul className="list-disc pl-4">
                <li>
                  Use the Website in any way that could damage, disable, overburden, or impair the Website or interfere with any other party's use of the Website.
                </li>
                <li>
                  Use the Website to collect or harvest personal data about other users without their consent.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">4. Package Selection and Booking</h2>
          <ul className="list-disc pl-4">
            <li>
              You agree to select a Package and fill out the required information truthfully and accurately.
            </li>
            <li>
              You understand that the information you provide during the booking process is subject to verification.
            </li>
            <li>
              You agree to verify your mobile number and provide accurate and complete information to complete the booking process.
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">5. Package Booking Terms</h2>
          <ul className="list-disc pl-4">
            <li>
              By booking a Package, you agree to the following terms:
              <ul className="list-disc pl-4">
                <li>
                  You have read and understood the Package details, including the itinerary, inclusions, and exclusions.
                </li>
                <li>
                  You have provided accurate and complete information during the booking process.
                </li>
                <li>
                  You understand that the Package is subject to availability and may be cancelled or modified due to unforeseen circumstances.
                </li>
                <li>
                  You agree to pay the Package price in full, as specified on the Website.
                </li>
                <li>
                  You understand that payment is non-refundable and non-transferable.
                </li>
                <li>
      You agree to be responsible for any damage to property caused by you or your actions during the Package.
    </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">6. Consent for Contacting</h2>
          <ul className="list-disc pl-4">
            <li>
              By booking a Package, you consent to being contacted by us or our representatives for the following purposes:
              <ul className="list-disc pl-4">
                <li>
                  To confirm your booking and provide additional information about the Package.
                </li>
                <li>
                  To offer you additional services or products related to the Package.
                </li>
                <li>
                  To request feedback or reviews about the Package.
                </li>
              </ul>
            </li>
            <li>
              You understand that you can opt-out of receiving such communications at any time by contacting us.
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">7. Payment</h2>
          <ul className="list-disc pl-4">
            <li>
              You agree to make payment for the selected Package in accordance with the payment terms and conditions specified on the Website.
            </li>
            <li>
              You understand that payment is non-refundable and non-transferable.
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">8. Cancellation and Refund</h2>
          <ul className="list-disc pl-4">
            <li>
              You understand that cancellation of a booking may be subject to cancellation fees and/or penalties.
            </li>
            <li>
              You understand that refunds, if applicable, will be processed in accordance with the refund policy specified on the Website.
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">9. Intellectual Property</h2>
          <ul className="list-disc pl-4">
            <li>
              All Content on the Website is the property of <a href="https://www.ujjainonwheels.in">ujjainonwheels.in</a> or its licensors.
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">11. Disclaimer of Warranties</h2>
          <ul className="list-disc pl-4">
            <li>
              The Website is provided on an "as is" and "as available" basis.
            </li>
            <li>
              We disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">13. Governing Law</h2>
          <ul className="list-disc pl-4">
            <li>
              These TNC shall be governed by and construed in accordance with the laws of India M.P..
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">14. Changes to TNC</h2>
          <ul className="list-disc pl-4">
            <li>
              We reserve the right to modify or update these TNC at any
              </li>
              </ul>
        </div>
        <div className="bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-bold mb-2">15. Contact Us</h2>
          <ul className="list-disc pl-4">
            <li>
            If you have any questions or concerns about these TNC, please contact us at <a href="mailto:care@ujjainonwheels.in" className=" text-[#FF8303]">care@ujjainonwheels.in</a> or  call us at <span className="font-bold text-[#FF8303]">9977886853</span>.
            <br/>
            By using the Website, you acknowledge that you have read, understand, and agree to be bound by these TNC.
              </li>
              
              </ul>
        </div>
    </div>
</div>
    </div>
  )
}
              