import { useParams } from 'react-router-dom';
import { packages2, packages3, packages4 } from '../../data/UjjainOmkareshwarOneDay';
import { Tooltip } from 'flowbite-react';
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useRef, useState } from "react";
import ModalBooking from '../BookingModal';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all'; 
export default function UjjainOmkareshwarDetails() {
  const [openModal, setOpenModal] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [showFullItinerary, setShowFullItinerary] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const packageId = id !== undefined ? parseInt(id) : null;
  const packageType = location.state.packageType;

  const packageDetailsRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    // Create GSAP timeline for animations
    const tl = gsap.timeline({ paused: true });

    // Animate elements on scroll trigger
    tl.fromTo(
      '.package-image, .package-details, .amenities-list li, .itinerary-toggle, .packagePrice',
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2, duration: 1, ease: 'power3.out' }
    );
    

    // Create ScrollTrigger animation
    const trigger = ScrollTrigger.create({
      trigger: packageDetailsRef.current,
      start: 'top center', // Trigger when the top of the element reaches the center of the viewport
      onEnter: () => { 
        tl.play(); // Play animation when entering the trigger
      },
      // You can also add other callbacks like onLeave, onEnterBack, etc.
    });

    // Cleanup on unmount
    return () => {
      trigger.kill();
    };
  }, [packageDetailsRef]);


  let selectedPackage: any;
   if(packageType === "packages2"){
    selectedPackage = packages2;
  }else if(packageType === "packages3"){
    selectedPackage = packages3;
  }else if(packageType === "packages4"){
    selectedPackage = packages4;
  }
  const packageItem = packageId !== null? selectedPackage.find((item: any) => item.id === packageId) : null;
  if (!packageItem) {
    return <div className="text-center py-8">Package not found</div>;
  }
  function handleToggleItinerary(): void {
    setShowFullItinerary(!showFullItinerary)
  }

  return (
    <div className="container mx-auto px-4 py-20 md:py-10 ">
        
          <h1 className="text-2xl font-bold mb-4 text-center  p-2 rounded-md bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] text-transparent bg-clip-text bodoni-libre">Package Details</h1>
      <div className="flex flex-col space-y-10 md:space-y-0 md:flex-row md:items-center" ref={packageDetailsRef}>
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <img src="/mahakal.jpg" alt="Package Image" className="sm:w-full sm:h-full rounded-lg object-cover shadow-md" />
        </div>
        <div className="flex-grow px-4 md:px-8 py-12 md:py-0">
          <div className="space-y-4">
            {/* <h3 className="text-xl font-semibold text-[#FF8303] packagePrice">Price: ₹{packageItem.price}</h3> */}
            <div className="space-y-2">
              <p className="text-lg font-medium">{packageItem.name}</p>
              <p className="text-base">{packageItem.pfor}</p>
              {/* <p className="text-base package-details">{packageItem.description}</p> */}
              <div className='flex flex-col md:flex-row w-full '>
            <h3 className="text-xl font-semibold text-[#FF8303] mr-2 ">Price: ₹{packageItem.price}</h3>
            {packageItem.priceWithoutFnB && <h3 className="text-xl font-semibold text-[#FF8303] noto-serif ">, Price without F&B: ₹{packageItem.priceWithoutFnB}</h3>}
            </div>
            </div>
            <p className="text-lg font-medium mb-2">Includes:</p>
            <ul className="list-disc space-y-2 itinerary-list  mt-4">
              {packageItem.includes.map((item: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined, index: Key | null | undefined)   => (
                <li key={index} className="text-gray-600">
                  {item}
                </li>
              ))}
            </ul>
            <Button onClick={() => setOpenModal(true)} className="bg-[#FE6E00] hover:bg-blue-700 text-white">Check Availability</Button>
          </div>
        </div>
      </div>
          <div className="px-4 py-4 md:py-0">
            {showFullItinerary && (
              <button onClick={handleToggleItinerary} className="text-blue-500 itinerary-toggle hover:text-blue-700">
                Hide
              </button>
            )}
            {!showFullItinerary && (
              <button onClick={handleToggleItinerary} className="text-[#FE6E00] itinerary-toggle hover:text-blue-700">
                Show Itinerary
              </button>
            )}
            <ul className="list-disc space-y-2 itinerary-list mt-4">
              {showFullItinerary && packageItem.itinerary.map((item: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined, index: Key | null | undefined)   => (
                <li key={index} className="text-gray-600">
                  {item}
                </li>
              ))}
            </ul>
          </div>

      <ModalBooking open={openModal} selectedPackage={packageItem} onClose={() => setOpenModal(false)} />
    </div>
  );
}