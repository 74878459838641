
// import { Avatar, Button, Checkbox, Label, Textarea, TextInput } from "flowbite-react";
// import { Rating } from "flowbite-react";
// import { useEffect, useRef, useState } from 'react';
// import { Modal } from "flowbite-react";
// import * as yup from 'yup';
// import { Formik, FormikContext, FormikHelpers, FormikProps } from 'formik';
// import axios from "axios";
// export default function Testimonial() {
//   const [openModal, setOpenModal] = useState(false);
//   const nameInputRef = useRef<HTMLInputElement>(null);
//   const [countdown, setCountdown] = useState(0);
//   const [otpError, setOtpError] = useState('');
//   const [otpCode, setOtpCode] = useState("");
//   const [otpSent, setOtpSent] = useState(false);
//   const [submitting, setSubmitting] = useState(false);
//   const [phoneError, setPhoneError] = useState('');
//   const [isVerified, setIsVerified] = useState(false);
//   const [reviews, setReviews] = useState([]);

  
//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await axios.get('https://www.ujjainonwheels.in/api/api/getReviews');
//         setReviews(response.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchReviews();
//   }, []);
//     interface FormData {
//     review: string;
//     name: string;
//     rating: string;
//     phone: string;
//   }
//   const startCountdown = (time: number) => {
//     setCountdown(time);
//     const intervalId = setInterval(() => {
//       setCountdown((prevCountdown) => {
//         if (prevCountdown - 1 === 0) {
//           clearInterval(intervalId);
//         }
//         return prevCountdown - 1;
//       });
//     }, 1000);
//   };
  
//   const handleSendOTP = async (number: string) => {
//     if (!number || !/^\d{10}$/.test(number)) {
//       setPhoneError('Invalid phone number');
//       return;
//     }
//     setPhoneError('');
//     try {
//       const response = await axios.post('https://www.ujjainonwheels.in/api/api/send_otp', { mobileNumber: number });
//       // handle the response
//       console.log(response.status);
//       if(response.status === 200){
//         startCountdown(60);
//         setSubmitting(true);
//         setOtpSent(true);
//       }
//     } catch (error) {
//       // handle the error
//       console.error(error);
//     }
//   };
//   const handleVerifyOTP = async (number: string, otp: string) => {
//     if(otp.length !== 4){ 
//       setOtpError('Invalid OTP');
//       return;
//     }
//     try {
//       const response = await axios.post('https://www.ujjainonwheels.in/api/api/verify_otp', { mobileNumber: number, otp: otp});
//       // handle the response
//       console.log(response.status);
//      setIsVerified(true)
//     } catch (error) {
//       // handle the error
//       console.error(error);
//     }
//   };
//   const validationSchema = yup.object().shape({
//     name: yup.string().required('Name is required'),
//     review: yup.string().required('Please write a review'),
//     rating: yup.number().min(0, 'Rating must be at least 0').max(5, 'Rating must be at most 5').required('Enter your rating'),
//   });
//   const handleSubmit = async (values: { name: string; phone: string; review: string; rating: string }, formikHelpers: FormikHelpers<{ name: string; phone: string; review: string; rating: string }>) => {
//     try {
//       await validationSchema.validate(values, { abortEarly: false });
//       // Submit the form data to your API or backend
//       console.log(values);
//       // return;
//       const response = await axios.post('https://www.ujjainonwheels.in/api/api/add_review', {
//         name: values.name,
//         phone: values.phone,
//         review: values.review,
//         rating: Number(values.rating),
//       });
//       if(response.status === 201) {
//         formikHelpers.resetForm(); // Clear form after successful submission
//         setOpenModal(false)
//         setOtpCode("");
//         setOtpSent(false);
//       }else{
//         alert("Error please try again later");
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <section id="testimonials" className="w-full py-12 md:py-24 lg:py-32 bg-[#F9F9F6]  dark:bg-[#313d4d] dark:text-white">
//       <div className="container px-4 md:px-6">
//         <div className="space-y-8">
//           <div className="text-center">
//             <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text bodoni-libre">What Our Customers Say</h2>
//             <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
//               Hear from our satisfied customers about their experiences with our tour packages.
//             </p>
//           </div>
//           <button
//             className="bg-[#007bff] text-white flex flex-row font-bold py-2 px-4 rounded-lg"
//             onClick={() => setOpenModal(true)}
//           >
//             <span>
//               Write a Review
//             </span>

//           </button>
//           <div className="grid grid-cols-1 sm:grid-cols-2 py-4 lg:grid-cols-3 gap-6 packagesCard">

//           {reviews.map((review: any, index) => (
//         <div key={index} className="bg-[#F9F9F6] min-w-[260px] rounded-lg shadow-lg p-6 space-y-4 dark:bg-[#313d4d] dark:text-white">
//           <div className="flex items-center gap-4">
//             <div>
//               <h4 className="font-semibold">{review.name}</h4>
//             </div>
//           </div>
//           <div className="flex items-center gap-2 text-xs font-semibold">
//             <div className="flex items-center gap-px">
//             <Rating>
//                 {[...Array(review.rating)].map((_, index) => (
//                   <Rating.Star key={index} />
//                 ))}
//                 {[...Array(5 - review.rating)].map((_, index) => (
//                   <Rating.Star key={index} filled={false} />
//                 ))}
//               </Rating>

//             </div>
//           </div>
//           <p className="text-[#495057]">{review.description}</p>
//         </div>
//       ))}
//           </div>
//         </div>
//         <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)} initialFocus={nameInputRef}>
//           <Modal.Header />
//           <Modal.Body>
//             <Formik
//               initialValues={{
//                 name: '',
//                 phone: '',
//                 review: '',
//                 rating: '',
//                 // Add other initial values as needed
//               }}
//               validationSchema={validationSchema}
//               onSubmit={handleSubmit}
//             >
//               {({ values, errors, handleChange, handleSubmit, resetForm }: FormikProps<FormData>) => (
//                 <form onSubmit={handleSubmit}>
//                   <div>
//                     <div className="mb-2 block">
//                       <Label htmlFor="name" value="Your Name" />
//                     </div>
//                     <TextInput id="name" value={values.name} onChange={handleChange} placeholder="Your Name" />
//                     {errors.name && <div className="text-red-500">{errors.name}</div>}
//                   </div>
//                   <div>
//                     <div className="mb-2 block">
//                       <Label htmlFor="phone" value="Your Mobile Number" />
//                     </div>
//                     <TextInput id="phone" value={values.phone} onChange={handleChange} type="tel" placeholder="Mobile Number" />
//                     {phoneError && <div className="text-red-500">{phoneError}</div>}
//                   </div>
//                   <div className="w-full mt-4">
                    
//                     <TextInput
//                       id="otp"
//                       value={otpCode}
//                       onChange={(e) => setOtpCode(e.target.value)}
//                       type={otpSent ? "text" : "hidden"}
//                       placeholder="Enter your OTP"
//                     />
//                     {countdown === 0 ? (
//                      <Button color={"blue"} type="button" onClick={() => handleSendOTP(values.phone)} >
//                      Send OTP
//                    </Button>
//                     ) : (
//                       <>
//                       <br/>
//                       <Button type="button" color={"blue"} onClick={() => handleVerifyOTP(values.phone, otpCode)}>
//                           Verify OTP
//                         </Button><div>
//                             OTP sent. Please check your phone.
//                             <br />
//                             resend OTP in {countdown}
//                           </div></>
//                     )}

//                   </div>
//                   <div>
//                     <div className="mb-2 block">
//                       <Label htmlFor="rating" value="Rate out of /5" />
//                     </div>
//                     <TextInput id="rating" value={values.rating} onChange={handleChange} placeholder="Write your rating out of 5" />
//                     {errors.rating && <div className="text-red-500">{errors.rating}</div>}
//                   </div>
//                   <div>
//                     <div className="mb-2 block">
//                       <Label htmlFor="review" value="Write your review" />
//                     </div>
//                     <Textarea id="review" value={values.review} onChange={handleChange} rows={2} placeholder="Write your review" />
//                     {errors.review && <div className="text-red-500">{errors.review}</div>}
//                   </div>
//                   <div className="w-full mt-4">
//                     <Button color={"blue"} disabled={!isVerified} type="submit">Submit</Button>
//                   </div>
//                 </form>
//               )}
//             </Formik>
//           </Modal.Body>
//         </Modal>
//       </div>
//     </section>
//   )
// }


import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { Rating } from "flowbite-react";
import { useEffect, useRef, useState } from 'react';
import { Modal } from "flowbite-react";
import * as yup from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Rating as StarRating } from '@smastrom/react-rating'
import axios from "axios";
export default function Testimonial() {
  const [openModal, setOpenModal] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [countdown, setCountdown] = useState(0);
  const [otpError, setOtpError] = useState('');
  const [otpCode, setOtpCode] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(5);
  const [userRating, setUserRating] = useState(2);

 
const changeRating = (value: number) =>{ 
  setRating(value);
}
const fetchReviews = async () => {
  try {
    const response = await axios.get('https://www.ujjainonwheels.in/api/api/getReviews');
    setReviews(response.data);
  } catch (error) {
    console.error(error);
  }
};
  
  useEffect(() => {
      fetchReviews();
  }, []);
    interface FormData {
    review: string;
    name: string;
    rating: string;
    phone: string;
  }
  let intervalId: string | number | NodeJS.Timeout | undefined;
  const startCountdown = (time: number) => {
    setCountdown(time);
     intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown - 1 === 0) {
          clearInterval(intervalId);
          setOtpSent(false);
          setOtpVerified(false);
          setOtpCode('');
          setOtpError('');
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };
  
  const handleSendOTP = async (number: string) => {
    if (!number || !/^\d{10}$/.test(number)) {
      setPhoneError('Invalid phone number');
      return;
    }
    setPhoneError('');
    try {
      const response = await axios.post('https://www.ujjainonwheels.in/api/api/send_otp', { mobileNumber: number });
      // handle the response 
      if(response.status === 200){
        startCountdown(60);
        setSubmitting(true);
        setOtpSent(true);
      }
    } catch (error) {
      // handle the error
      console.error(error);
    }
  };
  const handleVerifyOTP = async (number: string, otp: string) => {
    if(otp.length !== 4){ 
      setOtpError('Invalid OTP');
      return;
    }
    try {
      const response = await axios.post('https://www.ujjainonwheels.in/api/api/verify_otp', { mobileNumber: number, otp: otp});
      // handle the  
     if(response.status === 200){
       setIsVerified(true)
       setOtpVerified(true)
       clearInterval(intervalId);
      }else if(response.status === 401){
        setOtpError('Invalid OTP');
      }
    } catch (error) {
      setOtpError('Invalid OTP');
      // handle the error
      console.error(error);
    }
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    review: yup.string().required('Please write a review'),
  });
  const handleSubmit = async (values: { name: string; phone: string; review: string; rating: string }, formikHelpers: FormikHelpers<{ name: string; phone: string; review: string; rating: string }>) => {
 
    try {
      await validationSchema.validate(values, { abortEarly: false });
      // Submit the form data to your API or backend
 
      // return;
      const response = await axios.post('https://www.ujjainonwheels.in/api/api/add_review', {
        name: values.name,
        phone: values.phone,
        review: values.review,
        rating: rating,
      });
      if(response.status === 201) {
        formikHelpers.resetForm(); // Clear form after successful submission
        setOpenModal(false)
        setOtpCode("");
        setOtpSent(false);
        fetchReviews();
      }else{
        alert("Error please try again later");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section id="testimonials" className="w-full py-12 md:py-24 lg:py-32 bg-[#F9F9F6]  dark:bg-[#313d4d] dark:text-white">
      <div className="container px-4 md:px-6">
        <div className="space-y-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text bodoni-libre">What Our Customers Say</h2>
            <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
              Hear from our satisfied customers about their experiences with our tour packages.
            </p>
          </div>
          <button
            className=" bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text flex flex-row font-bold py-2 px-4 rounded-lg"
            onClick={() => setOpenModal(true)}
          >
            <span>
              Write a Review
            </span>

          </button>
          <div className="grid grid-cols-1 sm:grid-cols-2 py-4 lg:grid-cols-3 gap-6 packagesCard">

          {reviews.map((review: any, index) => (
        <div key={index} className="bg-[#F9F9F6] min-w-[260px] rounded-lg shadow-lg p-6 space-y-4 dark:bg-[#313d4d] dark:text-white">
          <div className="flex items-center gap-4">
            <div>
              <h4 className="font-semibold">{review.name}</h4>
            </div>
          </div>
          <div className="flex items-center gap-2 text-xs font-semibold">
            <div className="flex items-center gap-px">
            <Rating>
                {[...Array(review.rating)].map((_, index) => (
                  <Rating.Star key={index} />
                ))}
                {[...Array(5 - review.rating)].map((_, index) => (
                  <Rating.Star key={index} filled={false} />
                ))}
              </Rating>

            </div>
          </div>
          <p className="text-[#495057]">{review.description}</p>
        </div>
      ))}
          </div>
        </div>
        <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)} initialFocus={nameInputRef}>
          <Modal.Header />
          <Modal.Body>
            <Formik
              initialValues={{
                name: '',
                phone: '',
                review: '',
                rating: '',
                // Add other initial values as needed
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, handleChange, handleSubmit, resetForm }: FormikProps<FormData>) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="name" value="Your Name" />
                    </div>
                    <TextInput id="name" value={values.name} onChange={handleChange} placeholder="Your Name" />
                    {errors.name && <div className="text-red-500">{errors.name}</div>}
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="phone" value="Your Mobile Number" />
                    </div>
                    <TextInput id="phone" value={values.phone} onChange={handleChange} type="tel" placeholder="Mobile Number" />
                    {phoneError && <div className="text-red-500">{phoneError}</div>}
                  </div>
                  <div className="w-full mt-4">
                    
                  
                    

                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="rating" value="Rate Us" />
                    </div>
                    {/* <TextInput id="rating" value={values.rating} onChange={handleChange} placeholder="Write your rating out of 5" />
                    {errors.rating && <div className="text-red-500">{errors.rating}</div>} */}
                  </div>
                  <div className="w-full flex flex-row mt-4">
                  <StarRating style={{ maxWidth: 250 }} value={rating} onChange={changeRating} />
                        </div>
                        <br/>
                        <div>
                    <div className="mb-2 block">
                      <Label htmlFor="review" value="Write your review" />
                    </div>
                    <Textarea id="review" value={values.review} onChange={handleChange} rows={2} placeholder="Write your review" />
                    {errors.review && <div className="text-red-500">{errors.review}</div>}
                  </div>
                  <br/>
                  <TextInput
                      id="otp"
                      value={otpCode}
                      onChange={(e) => setOtpCode(e.target.value)}
                      type={otpSent ? "text" : "hidden"}
                      placeholder="Enter your OTP"
                      className={`transition duration-300 ${otpSent ? 'opacity-100' : 'opacity-0'}`}
                    />
                    {otpError && <div className="text-red-500">{otpError}</div>}
                  <div className="w-full mt-4">
                   {otpVerified && <Button className="bg-[#FF8303]" type="submit">Submit</Button>}
                    {countdown === 0 ? (
                     <Button className="text-white bg-[#FF8303] hover:bg-[#FE6E00]" type="button" onClick={() => handleSendOTP(values.phone)}>
                     Send OTP
                   </Button>
                    ) : (
                      <>
                      <br/>
                      {!otpVerified && <Button type="button" className="text-white bg-[#FF8303] hover:bg-[#FE6E00] " onClick={() => handleVerifyOTP(values.phone, otpCode)}>
                          Verify OTP
                        </Button>}
                        {!otpVerified &&  <div>
                            OTP sent. Please check your phone.
                            <br />
                            resend OTP in {countdown}
                          </div> }</>
                    )}
                  </div>
                  <div className="flex flex-row" >

                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </section>
  )
}
