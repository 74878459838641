import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export default function OurMotive() {
  const blockquoteRef = useRef(null);

  useEffect(() => {
    const blockquote = blockquoteRef.current;
    if (blockquote) {
      gsap.fromTo(blockquote, { opacity: 0, display: 'none' }, { opacity: 1, display: 'block', duration: 2, ease: 'power3.out' });
    }
  }, []);

  return (
    <>
      <br />
      <p  className="text-xl leading-relaxed md:mx-6 mx-2 p-4 md:p-6 lg:p-8 xl:p-10 bg-[#F9F9F6] rounded-lg shadow-md dark:bg-[#313d4d] bg-cover bg-center">
        <blockquote ref={blockquoteRef} className="dark:text-white-500 text-black ">
          <br/>
          <div  className=" top-0 left-0 right-0 bottom-0 p-4  dark:bg-gray-800 rounded-lg">
            <p className="text-xl font-medium text-gray-600 dark:text-white " >
              &#8220;Our motive is to make you meet the real divine forms of the Lord rather than just seeing the Statue or Idols of the God through practicality and spiritual stories.
              We let you explore the true heritage culture of the Holy and Historic Ujjain with our local insights and experience.
              Alongside our homely feel we will make you restore the relationship with our Eternal parents in one of the oldest city existing.&#8221;
            </p>
          </div>
          <span className=" bottom-0 right-0 p-2 text-gray-600 dark:text-white">
          {/* Ensuring to fulfill the motive of your visit. */}
          <br/>
          &nbsp; -UjjainOnWheels
          </span>
        </blockquote>
      </p>
    </>
  );
}