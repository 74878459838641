
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppRoutes from './routes';
import Navbar  from './components/Navbar';
import '@smastrom/react-rating/style.css';
import {Helmet} from 'react-helmet';
import MobileNavbar from './components/MobileNavbar';
function App() {
  return (
    <Router>
      <div className='dark:bg-gray-700 dark:text-white bg-[#F9F9F6]  '>
        {/* <div className='fixed top-0 left-0 right-0 z-10 '> */}
    <div className='hidden md:block'>
    <Navbar/>
    </div>
    <div className='flex md:hidden  bg-transparent h-22'>
    <MobileNavbar/>
    </div>
    <Helmet>
      <title>UjjainOnWheels</title> 
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Explore Ujjain's holy sites with ease! Ujjainonwheels.in offers hassle-free darshan packages with stay, food, pickup & drop-off. Book your unforgettable journey today! (Ujjainonwheels.in)" />
      <meta name="keywords" content="Ujjain, pilgrimage, darshan, tour packages, stay, food, pickup, drop-off, Ujjain, pilgrimage, darshan, hassle-free, convenient, all-inclusive" />
      <meta name="author" content="UjjainOnWheels" />
    </Helmet>
        {/* </div> */}
        {/* <div className='pt-14'> */}
        <Routes>
          <Route path="/*" element={<AppRoutes />} />
        </Routes>
        {/* </div> */}
      </div>
  </Router>
  );
}

export default App;
