import React, { useEffect, useState } from 'react';
import axios from 'axios';
function Heading() {
  const [shlok, setShlok] = useState('');
  const [meaning, setMeaning] = useState('');
  const [image, setImage] = useState('');
useEffect(  ()  => {
  axios.post('https://www.ujjainonwheels.in/api/api/get-daily-shringar').then((response) => {
    setShlok(response.data.shlok);
    setMeaning(response.data.meaning);
    setImage(response.data.image);
  })
}, [])


  const dateToday = new Date().getDate() + '-' + (new Date().getMonth()+1) + '-' + new Date().getFullYear() ;
  return (
    <section  
      className=" mx-auto p-4 w-full "
      style={{
        backgroundImage: `url('/heading-bg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      >
      <div
        className=" m-3 mt-16 backdrop-blur-xl bg-white/30 flex gap-2 flex-col items-center md:flex-row justify-center rounded-lg bg-cover bg-center  "
        
      >
        <div className="p-4  flex flex-col-reverse md:flex-row ">
          <div className=' word-break w-full p-5 '>
          <br/>
          <hr className="md:hidden" />
          <br/>
          
          <h1 className="text-3xl  font-bold font-playfair-display mt-2 text-center bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] inline-block text-transparent bg-clip-text noto-serif">Daily Blogs</h1>
          <p className="text-2xl mt-6 font-medium text-black font-istok-web">{shlok}</p>
          <p className="text-xl font-light text-gray-700 mt-2 roboto-condensed">{meaning}</p>
          </div>
          <div className='flex justify-center md:justify-center w-full'>
<div className='w-full text-center gap-2'>
      <div className="  rounded-full w-46  flex justify-center items-center ">

        <h1
          className=" text-center items-center justify-center text-2xl font-bold text-center md:text-left bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] inline-block text-transparent bg-clip-text noto-serif"
          >
          Daily श्रृंगार Darshan Updates
        </h1>
            </div>
        <br/>
        <p className='bottom-0 bondoi-libre text-center bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] text-transparent bg-clip-text text-xl  font-italic bg-white w-full '>

        </p>
        <div
        className="h-72 w-full md:w-full md:h-96 mt-0 relative rounded-lg bg-cover bg-center "
        style={{
          backgroundImage: `url('https://www.ujjainonwheels.in/api/public/daily/${image}')`,
          
        }}
      >
       <p className='bottom-0 absolute font-bold right-2 md:right-1 text-[#FE6E00] domine bg-white/0 rounded-lg  text-2xl '>
         {dateToday}
        </p>
      </div>
      </div>
      </div>
      </div>
      </div>
    </section>
  );
}

export default Heading;