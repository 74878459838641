const contentData = [
    {
      title: "DISCOVER THE DIVINE CHARM OF UJJAIN",
      description:
        "Explore the rich cultural heritage and natural beauty of Ujjain with our curated tour packages.",
      imageUrl: "carousel/ujjain.jpg",
      alt: "ujjain junction",
    },
    {
      title: "EXTREMELY POWERFUL MAHAKALESHWAR JYOTIRLINGA ",
      description: "Temple of the Owner and King of Ujjain . Why Mahakaaleshwar form of Shiv ji is extremely powerful? - In the easiest and the shortest possible way we can try understanding is when dooshan (a demon) saw the Mahakaal Swaroop of the Lord , he was not even able to surrender or run , he just got burned into ashes . Just try imagining how extreme care for devotees and burning anger for dooshan was there in his eyes which made dooshan explode instantly ,that is why the Mahakaal Swaroop of Shiv ji is extremely powerful and Gracious . In Satyug , the one responsible for best Goldy architectures i.e. Vishwakarma made constructions for Mahakal Mandir . Skandpuran's Avantikhand Shivpurana , Matsyapurana , Devibhagwat , Bhrammandpurana , Agnipurana , Garud Puran , Vishnu Puran , Vamana Puran , Ling Puran , Harivanshpurana and many more scriptures have glorified the importance and grandeur of Our Mahakal Baba . Also there are many scriptures which were only made to Glorify Mahakaleshwar Jyotirlinga .",
      imageUrl: "carousel/mahakaleshwar.jpg",
      alt: "Mahakaleshwar Jyotirlinga"
    },
    {
      title: "CHINTAMAN GANESH MANDIR",
      description:
        "The head of intelligence and Knowledge Ganeshji giving darshans in his three forms i.e. Chintamani , Icchamani and Siddhivinayak with Riddhi Maiya and Siddhi Maiya side by side . When Lord Ram came to Ujjain while exile at that time he established the Temple . It is believed that the diety is not made by a human and is self-proclaimed . When Siya Maiya felt thirsty at that time Lord Laxman made a well by an arrow , which is known as Laxman Bauli . Also here is a pretty place for Family Picnic . Current structure is believed to be of 11th - 12th century .",
      imageUrl: "carousel/chintaman.jpeg",
      alt: "Chintaman Ganesh Mandir"
    },
    {
      title: "RAJA VIKRAMADITYA SINGHASAN BATTISI ",
      description: "In between Rudrasagar behind Mahakal Temple , there is Singhasan Battisi of Raja Vikramaditya who only believed in justice and never took a side for injustice . Due to this quality , even the 32 cursed nymphs from the heaven talked to him . There are still some signs of Singhasan here which you will get Darshans of in your trip . Raja Vikramaditya also had nav-ratnas to glorify his royal court . Raja Vikramaditya sacrificed his own head 11 times with faith and expression of surrender as well as devoting himself for his Aaradhya Devi which again and again got re-attached with grace . This is a perfect example to educate ourselves about bali-pratha and spread awareness about protecting all beings . Raja Vikramaditya taught us Faith , importance and need of Justice , Devotion , Fearlessness as well as Unconditional Love .",
      imageUrl: "carousel/singhasan.jpg",
      alt: "Raja Vikramaditya Singhasan Battisi"
    },
    {
      title: "HARSIDDHI MATA MANDIR",
      description:
        "According to Shivpurana Maa Sati's Elbow came here . According to Skandpurana there were two demons Chand and prachand who even tried to trouble Mount Kailash , when Lord Shiv ji called for Devi Chandi and asked to kill these demons . After killing them both , Lord Shiv ji bestowed her with Blessings and a name i.e. Harsiddhi Matarani . Harsiddhi Maiya is also known as the Aaradhya Devi to Raja Vikramaditya . In between Maa MahaLaxmi and Maa MahaSaraswati there is Maa Harsiddhi in the Middle . In the back there is Maa Annapoorna Matarani Maiya .It is the 26th Shaktipeeth among all 51 .",
      imageUrl: "carousel/harsidhi.jpg",
      alt: "Harsiddhi Mata Mandir",
    },
    {
      title: "GADHKALIKA MATA MANDIR ( SHAKTIPEETH )",
      description: "This the place where lip of Mata Sati came . Gadhkalika Maiya is the Aaradhya devi of Mahakavi Kalidas . By Matarani's grace Mahakavi Kalidas got enlightened with the learnings of scriptures in this temple only . Gadhkalika Mata Mandir hold chief importance for tantrik siddhas . Maa Gadhkalika Matarani also has a piece of moon on her forehead . Here Matarani sits on a Marble stone and in front there is her Lion facing towards Maiya .",
      imageUrl: "carousel/gadhkalika.jpg",
      alt: "Gadhkalika Mata Mandir"
    },
    {
      title: "NAVGRAH SHANI MANDIR",
      description: "By mistakenly when King Vikramaditya disrespected Shanidev , then he faced many pains and obstacles in life . But when King prayed to Shanidev and made him happy , he got freed from all the pains and problems . Then as he promised to get a chief space for Shanidev , King Vikramaditya established a Navgrah Mandir where Shanidev is established as the Chief .",
      imageUrl: "carousel/shani_mandir.jpg",
      alt: "Navgrah Shani Manidir",
    },
    {
      title: "MIRACULOUS SIDDHAWAT",
      description: "Here is a Supernatural Banyan Tree ( Vat Vriksh ) which was once forcefully destroyed by Mughals and whose roots were burned and were studded by large hot iron pans , Still the miraculous Vat Vriksh began to develop its roots and grew up . As per Varahpurana Prayag has Akshaywat , Nashik has Panchwat , Vrindavan has Vamshiwat and Gaya has Gayawat . Just like these Ujjain has Holy Siddhawat . According to Skand Purana even Our Devi-Devta's did tapasya and meditated here .",
      imageUrl: "carousel/sidhwat.jpg",
      alt: "Siddhawat",
    },
    {
      title: "MAHARISHI GURU SANDIPANI MUNI ASHRAM",
      description: "Supreme Lord Krishna along with his elder Brother Balram came to study in the guidance of Maharishi Sandipani Muni Gurumaharaj . When the Lord came to Gurumaharaj's Ashram , he didn't showed any of the ego which could've came from the past winnings of his life instead he bowed down to Gurumaharaj to get his acceptance and mercy . Krishna , Sudama (Krishna's bestfriend) and Balram learned about our scriptures , elephant and horse riding , 64 deep art forms and many more things by Maharishi Sandipani Muni Gurumaharaj . The place could be seen mentioned in Shrimad Bhagwatam , Mahabharat , Agnipurana and Bhramvaivart Purana . The  Maharishi Sandipani Muni Ashram is a perfect place full of information to spend time while gaining knowledge and Spiritual Upgradation .",
      imageUrl: "carousel/sandipani.jpg",
      alt: "Sandipani Ashram",
    },
    {
      title: "BHARTHARI CAVES ",
      description: "Raja Bharthari was elder to Raja Vikramaditya . After getting unintentional betrayal and after the realisation of life , he walked away from the Royal palace & it's perks and took initiation from Saint Gorakhnath Ji from Nath Sampradaya .Also there is a Samadhi of Raja Bharthari in Alwar . It is believed that a lot of time ago the caves were functional and were supposed to take you to Char Dham , but now you can only see the starting of the caves .",
      imageUrl: "carousel/bhartari.jpg",
      alt: "Bhartari Caves",
    },
    {
      title: "SHRI SHRI RUKMANI DWARKADHISH GOPAL MANDIR ",
      description: "The Temple was made in 19th century by the Maharani Baijabai of Maharaja Daulatrao ji Scindia . Ujjain's Rukmani Dwarkadhish temple also holds historical importance as when Lokmanya tilak stood up to organise Ganeshotsav and the chain of this rebellious movement was commenced in the padsaal of Gopal Mandir . Later the same sabha (Sarvajanik Sabha) only became the national initiative of Ganeshotsav .",
      imageUrl: "carousel/gopal.jpg",
      alt: "Gopal Mandir",
    },
    {
      title: "ANCIENT NAMES OF UJJAIN ",
      description: "Kanak Shringa, Padmavati, Vishala, Chudamani, Bhogwati, Hiranyawati, Avanti, Avantipur, Avantika, Avantipuri, Avanti Nagari, Avantikapur, Amravati, Vishakha, Pushpa, Mahakalpuri, Kratrini, Pratikalpa, Kumudhviti, Kushsthali. ",
      imageUrl: "carousel/ancient.jpg",
      alt: "Old Names of Ujjain",
    },
  ];
export {contentData}  