// routes.js
import React from 'react';

import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Tnc from './pages/TnC';
import About from './pages/AboutUs';
import Contact from './pages/ContactUs';
import NotFound from './pages/NotFound';
import PackageDetails from './pages/PackageDetails';
import SinglePackages from './pages/SinglePackages';
import UjjainOmkareshwar from './pages/UjjainOmkareshwarTwoDays';
import UjjainOmkareshwarOneDay from './pages/UjjainOmkareshwarOneDay';
import UjjainOmkareshwarOneDayDetails from './pages/details/UjjainOmkareshwarOneDay';
import SpiritualUjjain from './pages/Spiritual-Ujjain-Guide';
import UjjainOmkareshwarService from './pages/Ujjain-Omkareshwar-Service';
import UjjainOmkareshwarDetails from './pages/details/UjjainOmkareshwarTwoDays';
import UjjainOmkareshwarServiceDetails from './pages/details/UjjainOmkareshwarServiceDetails';
import SpiritualUjjainGuideDetails from './pages/details/SpiritualUjjainGuideDetails';
import SinglePackagesDetails from './pages/details/SinglePackageDetails';
const AppRoutes = () => (
  <Routes>
    <Route  path="/" element={<Home />} />
    <Route  path="/about" element={<About />} />
    <Route  path="/contact" element={<Contact />} />
    <Route  path="/terms" element={<Tnc />} />
    <Route  path="/packages/:id" element={<PackageDetails />} />
    <Route  path="/single-packages" element={<SinglePackages />} />
    <Route  path="/single-packages-details/:id" element={<SinglePackagesDetails />} />
    <Route  path="/ujjain-omkareshwar" element={<UjjainOmkareshwar />} />
    <Route  path="/ujjain-omkareshwar-one-day" element={<UjjainOmkareshwarOneDay />} />
    <Route  path="/ujjain-omkareshwar-one-day-details/:id" element={<UjjainOmkareshwarOneDayDetails />} />
    <Route  path="/spiritual-ujjain" element={<SpiritualUjjain />} />
    <Route  path="/ujjain-omkareshwar-service" element={<UjjainOmkareshwarService />} />
    <Route  path="/ujjain-omkareshwar-details/:id" element={<UjjainOmkareshwarDetails />} />
    <Route  path="/ujjain-omkareshwar-service-details/:id" element={<UjjainOmkareshwarServiceDetails />} />
    <Route  path="/spiritual-ujjain-details/:id" element={<SpiritualUjjainGuideDetails />} />
    {/* <Route path="*" element={<NotFound />} /> */}
  </Routes>
);

export default AppRoutes;