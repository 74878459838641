import { useEffect } from "react";
import { Helmet } from "react-helmet";
export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="container mx-auto px-4 py-20 md:py-10 bg-gradient-to-b from-[#fff5eb] to-[#fffcf9] bg-blend-multiply bg-opacity-90 h-screen">
      <Helmet>
        <title>About Us - Ujjain on Wheels</title>
        <meta name="description" content="About Us - Ujjain on Wheels" />
      </Helmet>
      <h2 className="text-3xl font-bold text-center text-[#FE6E00]  mb-4 dark:text-gray-400">About Us</h2>
      <p className="text-lg leading-relaxed text-gray-600 dark:text-gray-400 text-justify">
        Ujjainonwheels provide you with every accessible service required to ensure your Ujjain Darshan experience elegant and memorable.
        <br/>
        <br/>
        Our services ensure your comfort and safety throughout the trip and save your time from hagglings with troublesome local vendors.
        We provide a personal localite companion to take care of you in every of our Ujjain packages.
        <br/>
        <br/>
      
        Our motive is to make you meet the real divine forms of the Lord rather than just seeing the statue or idols of the God through practical logics and stories. Ujjain on Wheels makes you explore the true heritage culture of holy and historic Ujjain with our local insights and experience.
         we help you to restore your relationship with the Lord in one of the oldest City existing.
        Ujjain on Wheels only provides and promotes cut-to-cut original knowledge with reference to Vedic scriptures and our Guru Parampara's teachings.
      </p>
      
    </section>
  );
}