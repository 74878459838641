import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function MobileNavbar() {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (checked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [checked]);
    return (
            <div className=" h-20 absolute w-full flex ">
            <div className=" flex flex-row justify-center bg-transparent ">
            <img src="/ujjainonwheels.png" className="ml-4 h-16 mt-2 w-26 md:h-10" alt="Ujjainonwheels" />
            {/* Ensuring to fulfill the motive of your visit. */}
            </div>
            <div className="navigation  bg-transparent ">
      <input checked={checked} type="checkbox" onClick={() => setChecked(!checked)} className="navigation__checkbox" id="nav-toggle"/>
      <label htmlFor="nav-toggle" className="navigation__button">
        <span className="navigation__icon top-0" aria-label="toggle navigation menu"></span>
      </label>
      <div className="navigation__background"></div>

      <nav className="navigation__nav" role="navigation">
        <ul className="navigation__list">
          <li className="navigation__item">
            <Link to="/" className="navigation__link text-black" onClick={() => setChecked(false)}>Home</Link>
          </li>
          <li className="navigation__item">
            <Link to="/about" className="navigation__link" onClick={() => setChecked(false)} >About-us</Link>
          </li>
          <li className="navigation__item">
            <Link to="/contact" className="navigation__link" onClick={() => setChecked(false)}>Contact-us</Link>
          </li>
    </ul>
  </nav>
</div>
        </div>
    );
}