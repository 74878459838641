// import { Link, useLocation  } from 'react-router-dom';
// import { Button, Navbar } from "flowbite-react";
import { DarkThemeToggle, Flowbite } from "flowbite-react";

// const NavbarComponent = () => {
//   const location = useLocation();
//   return (
//     <>
//       <Navbar fluid className=" bg-white pr-1 pl-1 md:pr-12 md:pl-12 ">
//         <Navbar.Brand href="/">
//           <img src="ujjainonwheels.png" alt="ujjainonwheels" className="w-16 h-12" /><br/>
//         </Navbar.Brand>
//         <div className="lg:hidden">
//           <Flowbite > <DarkThemeToggle className="p-0 m-0" ></DarkThemeToggle> </Flowbite>
//         </div>
//         <Navbar.Toggle />
//         <Navbar.Collapse>
//         <Navbar.Link as={Link} to="/" className={location.pathname === '/' ? 'text-blue-500' : ''}>
//         Home
//       </Navbar.Link>
//       <Navbar.Link as={Link} to="/about" className={location.pathname === '/about' ? 'text-blue-500' : ''}>About</Navbar.Link>
//       <Navbar.Link as={Link} to="/contact" className={location.pathname === '/contact' ? 'text-blue-500' : ''}>Contact</Navbar.Link>
//     </Navbar.Collapse>
//       </Navbar>
//     </>
//   );
// }
// export default NavbarComponent;
// className={location.pathname === '/' ? 'text-blue-500' : ''}
// "use client";
import {Link, useLocation } from "react-router-dom";
import { Navbar } from "flowbite-react";

export default function Component() {
  const location = useLocation();
  return (
    <Navbar fluid   className= {`pl-6  pr-6 ${location.pathname === '/' ? ' bg-transparent absolute w-full z-10' : 'bg-[#fff5eb] '}  `}>
      <Navbar.Brand as={Link} to="https://ujjainonwheels.in">
        <img src="/ujjainonwheels.png" className="mr-3 h-14 w-22 md:h-10" alt="Flowbite React Logo" />
       </Navbar.Brand>
      {/* <div className="mr-4 w-20 flex md:justify-between"> */}
      <div className=" flex md:justify-between" >
        {/* <DarkThemeToggle className="p-0  md:hidden " ></DarkThemeToggle> */}
        
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <div className="flex flex-col items-end  leading-8 md:flex-row md:gap-4">

        {/* <DarkThemeToggle className="p-0 m-0 hidden md:block"></DarkThemeToggle> */}
        <Link to="/" className={` hover:text-[#FD5602] w-full ${location.pathname === '/' ? 'text-white  md:bg-transparent' : 'text-[#FE6E00] '} transition duration-300 hover:underline `}>
    Home
  </Link>
  <Link to="/about" className={` hover:text-[#FD5602] w-full ${location.pathname === '/' ? 'text-white md:bg-transparent ' : 'text-[#FE6E00] '} transition duration-300 hover:underline`}>
    About
  </Link>
  <Link to="/contact" className={` hover:text-[#FD5602] w-full ${location.pathname === '/' ? 'text-white md:bg-transparent ' : 'text-[#FE6E00] '} transition duration-300 hover:underline`}>
    Contact
  </Link>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}