const packages1 = [
    {
      id: 1,
      name: "Sacred Ujjain: A Day of Pilgrimage",
      days: "1Day / 1Night",
      description: "Single day end to end Ujjain packages (with a spiritually guided Ujjain darshan experience by a localite companion). ",
      amenities: [
          { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M168-192q-70 0-119-49T0-360q0-64 41.5-112T146-526l-50-50H0v-48h156l132 106 144-58h155l-95-120H384v-72h142l109 137 133-89v144h-89l49 61q16-5 31.5-9t32.5-4q70 0 119 49t49 119q0 70-49 119t-119 49q-70 0-119-49t-49-119q0-32 12-61t33-53l-17-21-124 207H384l-50-50q-8 63-55.5 104.5T168-192Zm0-72q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm278-240-126 50 126-50h128-128Zm346 240q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm-305-96 87-144H446l-126 50 94 94h73Z"/></svg>`, name: "Pick-up, Drop-off" },
          { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
          { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
          { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
          ],
    },
    {
      id: 2,
      name: "Ujjain and Omkareshwar: A Spiritual Odyssey",
      days: "2Days / 2Nights",
      description: "End to end Ujjain + Omkareshwar Jyotirlinga Darshan Packages (with a spiritually guided Ujjain darshan experience by a localite companion).",
      amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
    },
    {
      id: 3,
      name: "Ujjain + Omkareshwar: A Miraculous Combo",
      days: "1Day / 1Night",
      description: `Single day end to end Ujjain + Omkareshwar Jyotirlinga Darshan Packages with 3/4th of a Spiritually guided Ujjain darshan experience by a localite companion.`,
      amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
    },
    // {
    //   id: 3,
    //   name: "Spiritual Ujjain: A Guided Experience",
    //   days: "2N/3D",
    //   description: "Spiritually guided Ujjain Darshan experience service.",
    //   amenities: [
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
    //     ],
    // },
    
    {
      id: 4,
      // name: "Ujjain to Omkareshwar: A Spiritual Sojourn",
      name: "Ujjain to Omkareshwar Jyotirlinga Darshan: Round Trip",
      // days: "Taxi Services Ujjain to Omkareshwar, Omkareshwar to Ujjain",
      description: "Ujjain to Omkareshwar  round trip Taxi Service",
      amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
    },
    // {
    //   id: 4,
    //   name: "Ujjain to Omkareshwar: A Spiritual Sojourn",
    //   days: "2N/3D",
    //   description: "Omkareshwar  Jyotirlinga Darshan Service.",
    //   amenities: [
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
    //     { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
    //     ],
    // },
    {
      id: 5,
      // name: "Ujjain Your Way ",
      name:"Spiritually guided Ujjain exploration with hidden gems",
      // description: "Customise your own personalised package by choosing our services and get a bill.",
      description: "Explore the miraculous importance of Sacred Ujjain with 11 main Temples & religious sites of our holy city.",
      amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
    },
  ];

  export {packages1}