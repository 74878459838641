
import { Accordion } from "flowbite-react";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
export default function FAQ() {
  const featuresRef = useRef(null);

  useEffect(() => {
    // IntersectionObserver options
    const options = {
      root: null, // Observe within the viewport
      threshold: 0.3, // Trigger animation when 50% or more is visible
    };

    // Create IntersectionObserver
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          animateFeaturesIn(featuresRef.current);
          observer.unobserve(entry.target); // Unobserve after animation
        }
      });
    }, options);

    // Observe the features section
    if (featuresRef.current) {
      observer.observe(featuresRef.current);
    }

    return () => {
      // Clean up observer on unmount
      if (observer && featuresRef.current) {
        observer.unobserve(featuresRef.current);
      }
    };
  }, []);
  
  const animateFeaturesIn = (features: any) => {
    // Animate individual features with stagger and custom timings
    gsap.fromTo(
      features.querySelectorAll('.animationInfo'),
      { opacity: 0, y: 20 }, // Initial state
      {
        opacity: 1,
        y: 0,
        stagger: 0.2, // Stagger animation between elements by 0.2 seconds
        duration: 1, // Individual animation duration of 0.8 seconds
        ease: 'power3.out' // Easing function for smooth animation
      }
    );
  };

  return (
    <section ref={featuresRef} className="flex flex-col justify-center align-center py-4 md:flex-row bg-gradient-to-b from-[#fffcf9] to-[#F9F9F6] bg-blend-multiply bg-opacity-90 " >
     <div className="w-full flex flex-col content-center justify-center items-center break-words align-center px-5" >
       <h1 className="text-3xl font-bold md:hidden text-center tracking-tighter sm:text-5xl xl:text-6xl  bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text bodoni-libre ">FAQs</h1>
       <h1 className="text-3xl font-bold md:block hidden text-center tracking-tighter sm:text-5xl xl:text-6xl  bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text bodoni-libre y-6 ">Frequently Asked Questions</h1>
      <p className="text-xl text-center text-gray-500 dark:text-gray-400">Have questions? We are here to answer them.</p>

     </div>
    <br/>
    <Accordion className="w-full">
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title >I am coming to Ujjain for the first time and am worried about frauds, misguided entries and troublesome crowd?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-500">
            Just give us a call and we will give our best to assure your Safety and our Localite companion will take you from the easiest entries possible to save you from getting lost in crowd.
          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title >I have a Single day for both Ujjain and Omkareshwar Jyotirlinga Darshan, can it be possible?</Accordion.Title>
        <Accordion.Content>
          
          <p className="text-gray-500 dark:text-gray-400">
           Yes, if you arrive before 6 A.M. and have a complete day for two, then you can opt for our single day Ujjain and Omkareshwar Jyotirlinga Darshan packages.
          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title>What do we get in Ujjain Darshan Experience?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">Ujjain Darshan Experience with ujjain on wheels provide you with a spiritually guided Darshans of Main Ancient Temples and Holy Places, along miraculous stories of Lord by a car with a Localite companion who will guide you at every difficulty.</p>
          
        </Accordion.Content>
      </Accordion.Panel >
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title>I Booked a wrong package and am worried about the same?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">Completely got you there is no problem, we will show you a convenient package and in the same time we will transfer it with additional charges if required.</p>
          
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title>What to do in Ujjain?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">There is a whole universe waiting to talk to you in Ujjain , for this you have to hear about spiritual stories , history , significance and deep Importance of Ujjain .When you Bow down in front of Extremely Powerful Temples of our Holy City Ujjain , after starting to surrender and paying respect with faith and love , one can feel instant relaxation and will start to know the purpose of life.</p>
          
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title>What are the top Spiritual Tourist Attractions in Ujjain ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">Along with these Ujjain on Wheels let you visit hidden treasures of Ujjain too as per your wish.</p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title>What is the best time in the year to visit Ujjain?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">October to March is officially the best time for outsiders to visit Ujjain. But here with Ujjain on wheels we don't let you feel like outsiders, so whenever you have your mood and precious
             time thinking to be planned for Ujjain , you can directly contact us and we will answer your queries.</p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className="animationInfo" >
        <Accordion.Title>What is Ujjain famous for?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400"> Ujjain is famous for its rich heritage spiritual culture and the historic importance of the city from ancient times. Also it is one of the oldest city existing on earth.</p>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
                  </section>
  );
}
