// import { Tooltip } from "flowbite-react";
// // import { packages1, packages2, packages3, packages4,  } from "../data/Packages";
// import {packages1} from "../data/AllPackages"
// import { Link } from "react-router-dom";
// export default function PackageDisplay() {
  
//   return (
//     <section id="packages" className="  py-12  lg:py-32  sm:snap-x w-full dark:bg-gray-700 dark:text-white bg-gradient-to-b from-[#fff5eb] to-[#fffcf9] bg-blend-multiply bg-opacity-90 ">
//       <div className="container px-4 md:px-6">
//         <div className="space-y-8">
//           <div className="text-center">
//             <h2 className='text-3xl font-bold tracking-tighter sm:text-4xl bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text bodoni-libre'>
//               Explore Our Tour Packages
//             </h2>
//             <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
//               Choose from our diverse range of tour packages to create your
//               perfect Ujjain experience.
//             </p>
//           </div>
//          <div className="text-lg font-semibold text-gray-600 mb-4 text-center">Packages for 1 Person</div>
//          <div className="flex flex-wrap md:gap-12  md:py-8 px-8 py-10  justify-center packagesCard"  >
  
//             {packages1.map((packageItem) => (
//               <div 
//               key={packageItem.id} 
//               className="bg-alice-blue md:ml-0 ms-5  snap-center min-w-[260px] md:w-1/2 lg:w-1/3 xl:w-1/4  rounded-3xl overflow-hidden shadow-lg transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl cursor-pointer dark:bg-gray-800 dark:text-white-800"
//               >
//                 <Link to={`/packages/${packageItem.id}`} state={{ packageType: "packages1" }} className="min-w-[260px]  snap-center md:w-1/2 lg:w-1/3 xl:w-1/4 ">
//                 <img src="/mahakal.jpg" alt={packageItem.name + " Package Image"} className="w-full h-40 object-cover rounded-t-lg group-hover:brightness-50" />
//                 <div className="p-4 space-y-2">
//                   <h3 className="text-xl font-semibold text-center bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]  text-transparent bg-clip-text ">{packageItem.name}</h3>
//                 <div className="grid grid-cols-2 justify-between items-center">
//                   <div className="flex items-start gap-2 flex-col">
//                   {/* <h3 className="text-xl font-semibold text-[#FF8303]">₹{packageItem.price}</h3> */}
//                   <ul className="flex flex-wrap gap-2 list-none m-0 p-0">
//                     {packageItem.amenities.map((amenity, index) => (
//                         <li key={index} className="flex items-center mb-2">
//                       <Tooltip content={amenity.name} style="light">
//                       <span dangerouslySetInnerHTML={{ __html: amenity.icon }} />
//       </Tooltip>
//                     </li>
//                     ))}
//                   </ul>
//                   </div>
//                   <div className="flex items-center flex-col">
//                   {/* <h4 className="text-lg font-medium">Amenities</h4> */}
//                   <p className="text-lg font-medium">{packageItem.days}</p>
//                   </div>
//                   </div>
//                   <p className="text-[#495057]">{packageItem.description}</p>
//                 </div>
//                 </Link>
//               </div>
//             ))}
//           </div>
//           </div>
          
//          <div className="text-lg font-semibold text-gray-600 mb-4 text-center">Packages for 2 Persons</div>
//          <div className="flex flex-wrap md:gap-12  md:py-8 px-8 py-10  justify-center packagesCard"  >
  
  
//           <div className="text-center ">
//   <h3 className="text-xl font-semibold text-gray-600 mb-4">
//     Traveling 5 or more persons?
//   </h3>
//   <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
//     For groups of 5 or more, we offer customized packages to cater to your specific needs.
//     Contact us to discuss your requirements and get a personalized quote.
//   </p>
//   <div className="flex justify-center gap-4">
//     {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300">
//       Contact Us
//     </button> */}
//     <Link to="/contact" className="text-[#FE6E00] text-xl hover:underline">
//       Contact Us
//     </Link>
//   </div>
// </div>
//         </div>
//       </div>
//     </section>
//   );
// }


import { Tooltip } from "flowbite-react";   
import {packages1} from "../data/PackagesDisplay"
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { Helmet } from "react-helmet";
export default function PackageDisplay() {
  const featuresRef = useRef(null);

  const animateFeaturesIn = (features: any) => {
    gsap.fromTo(
      features.querySelectorAll('.animationInfo'),
      {opacity: 0, y: -30 }, 
      {
        opacity: 1,
        y: 0,
        stagger: 0, 
        duration: 1, 
        ease: 'linear' 
      }
    );
  };
  useEffect(() => {

    const options = {
      root: null, 
      threshold: 0.2, 
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          animateFeaturesIn(featuresRef.current);
          observer.unobserve(entry.target); 
        }
      });
    }, options);

    if (featuresRef.current) {
      observer.observe(featuresRef.current);
    }
    
    return () => {
      if (observer && featuresRef.current) {
        observer.unobserve(featuresRef.current);
      }
    };
  }, []);
 

  return (
    <section id="packages"  ref={featuresRef} className="py-12 lg:py-32   sm:snap-x w-full dark:bg-gray-700 dark:text-white bg-gradient-to-b from-[#fff5eb] to-[#fffcf9] bg-blend-multiply bg-opacity-90 ">
      
      <div  className="container px-4 md:px-6">
        <div className="space-y-8">
          <div className="text-center ">
            <h2 className='text-3xl font-bold tracking-tighter sm:text-4xl bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42]   text-transparent bg-clip-text bodoni-libre'>
              Explore Our Tour Packages
            </h2>
            <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
              Choose from our diverse range of tour packages to create your
              perfect Ujjain experience.
            </p>
          </div>
         <div className="grid grid-cols-1 animationInfo gap-8 justify-items-center"> 
  
            {packages1.map((packageItem) => (
              <div 
             
              key={packageItem.id} 
              className="bg-alice-blue snap-center animationInfo opacity-0 min-w-[260px] w-3/4 rounded-3xl overflow-hidden shadow-lg transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl cursor-pointer dark:bg-gray-800 dark:text-white-800"
              >
                {/* <Link to="/single-packages" state={{ packageType: "packages1" }} className="min-w-[260px] w-full md:flex-row flex-col flex snap-center"> */}
                <Link
                  to={
                    packageItem.id === 1
                      ? `/single-packages`
                      : packageItem.id === 2
                        ? `/ujjain-omkareshwar`
                          : packageItem.id ===3
                            ? `/ujjain-omkareshwar-one-day`
                            // : packageItem.id ===4
                            // ? `/ujjain-omkareshwar-service`
                            : `/contact`
                  }
                  state={{ package: packageItem }}
                  className="min-w-[260px] w-full md:flex-row flex-col flex snap-center"
                >
                <img src="/mahakal.jpg" alt={packageItem.name + " Package Image"} className="w-full md:w-1/4 min-w-[260px] h-52 md:h-60 object-cover md:rounded-3xl group-hover:brightness-50" />
                <div className="p-4  space-y-2 flex flex-col w-full">
                  <h3 className="text-2xl font-bold  noto-serif bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] text-transparent bg-clip-text ">{packageItem.name}</h3>
                <div className="flex flex-row justify-between items-center">
                  <div className="flex items-center flex-col">
                  <p className="text-lg font-medium">{packageItem.days}</p>
                  </div>
                  </div>
                  <p className="text-[#564500] font-medium text-lg">{packageItem.description}</p>
                </div>
                </Link>
              </div>
            ))}
          </div>
          </div>
            <br/>
            <hr/>
            <br/>
  
          <div className="text-center ">
            <h3 className="text-xl font-semibold text-gray-600 mb-4">
                Traveling 5 or more persons?
            </h3>
            <p className="max-w-[700px] mx-auto text-[#6c757d] md:text-xl/relaxed">
                For groups of 5 or more, we offer customized packages to cater to your specific needs.
                Contact us to discuss your requirements and get a personalized quote.
            </p>
            <div className="flex justify-center gap-4">
                <Link to="/contact" className="text-[#FE6E00] text-xl hover:underline">
                Contact Us
                </Link>
            </div>
            </div>
        </div>
      {/* </div> */}
    </section>
  );
}