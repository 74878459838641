import { Button, Checkbox, Datepicker, Label, Textarea, TextInput } from "flowbite-react";
import { Rating } from "flowbite-react";
import { useEffect, useRef, useState } from 'react';
import { Modal } from "flowbite-react";
import * as yup from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import axios from "axios";

interface FormData {
  name: string;
  phone: string;
}

interface ModalBookingProps {
  open: boolean;
  onClose: () => void;
  selectedPackage: {
    priceWithoutFnB: any;
    name: string;
    price: number;
    pfor: string;
    selectedItem: string,
  };
  // onSubmit: (values: FormData, selectedDate: String) => void;
}

const ModalBooking = ({ open, onClose, selectedPackage }: ModalBookingProps) => {
  const [openModal, setOpenModal] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [countdown, setCountdown] = useState(0);
  const [otpError, setOtpError] = useState('');
  const [otpCode, setOtpCode] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [resendButton, setResendButton] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(`${new Date().getFullYear()}/${new Date().getMonth()+1}/${new Date().getDate()+1}`);
  const [withoutFb, setWithoutFb] = useState(false);
  interface FormData {
    name: string;
    phone: string;
    selectedDate: string;
  }
    let intervalId: string | number | NodeJS.Timeout | undefined;
    const startCountdown = (time: number) => {
      setCountdown(time);
       intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown - 1 === 0) {
            clearInterval(intervalId);
            setOtpSent(false);
            setOtpVerified(false);
            setOtpCode('');
            setOtpError('');
          }
          return prevCountdown - 1;
        });
      }, 1000);
    };
    const handleDateChange = (e:any) => {
      // const newDate = new Date(e.target.value);
      setSelectedDate(e.toLocaleDateString());
    };
    
    const handleSendOTP = async (number: string) => {
      if (!number || !/^\d{10}$/.test(number)) {
        setPhoneError('Invalid phone number');
        return;
      }
      setPhoneError('');
      try {
        const response = await axios.post('https://www.ujjainonwheels.in/api/api/send_otp', { mobileNumber: number });
        // handle the response
        if(response.status === 200){
          startCountdown(60);
          setSubmitting(true);
          setOtpSent(true);
          // onClose();
        }
      } catch (error) {
        // handle the error
        console.error(error);
      }
    };
    const handleVerifyOTP = async (number: string, otp: string) => {
      if(otp.length !== 4){ 
        setOtpError('Invalid OTP');
        return;
      }
      try {
        const response = await axios.post('https://www.ujjainonwheels.in/api/api/verify_otp', { mobileNumber: number, otp: otp});
        // handle the response 
       if(response.status === 200){
         setIsVerified(true)
         setOtpVerified(true)
         clearInterval(intervalId);
        }else if(response.status === 401){
          setOtpError('Invalid OTP');
        }
      } catch (error) {
        setOtpError('Invalid OTP');
        // handle the error
      }
    };
    const validationSchema = yup.object().shape({
      name: yup.string().required('Name is required'),
    });
    const packageName = withoutFb ? `${selectedPackage.selectedItem} without F&B` : selectedPackage.selectedItem;
    const handleSubmit = async (values: { name: string; phone: string; selectedDate: string; }, formikHelpers: FormikHelpers<{ name: string; phone: string; selectedDate: string; }>) => {
      
      // return;
      try {
        await validationSchema.validate(values, { abortEarly: false });
        // Submit the form data to your API or backend
        
        // return;
        const response = await axios.post('https://www.ujjainonwheels.in/api/api/add_users', {
          name: values.name,
          phone: values.phone,
          date: selectedDate,
          package_name: packageName
        });
        if(response.status === 201) {
          formikHelpers.resetForm();
          setOpenModal(false)
          setOtpCode("");
          setOtpSent(false);
          onClose();
          setTimeout(() => {
            setOpenModalConfirm(true);
          }, 1000);
        }else{
          alert("Error please try again later");
        }
      } catch (error) {
        console.error(error);
      }
    };
    
    // console.log(selectedPackage);
  return (
    <>
   <Modal show={open} size="md" popup onClose={onClose}>
          {/* <Modal.Header /> */}
          <Modal.Header className="text-xl font-bold">
            Booking Details
          </Modal.Header>
          <p className="text-md mx-6 text-gray-500 dark:text-gray-400 ">

          {packageName}
          </p>
          <Modal.Body>
            <Formik
              initialValues={{
                name: '',
                phone: '',
                selectedDate:'',
                // Add other initial values as needed
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, handleChange, handleSubmit, resetForm }: FormikProps<FormData>) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="name" value="Your Name" />
                    </div>
                    <TextInput id="name" value={values.name} onChange={handleChange} placeholder="Your Name" />
                    {errors.name && <div className="text-red-500">{errors.name}</div>}
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="phone" value="Your Mobile Number" />
                    </div>
                    <TextInput id="phone" value={values.phone} onChange={handleChange} type="tel" placeholder="Mobile Number" />
                    {phoneError && <div className="text-red-500">{phoneError}</div>}
                  </div>
                  <div>
                  <div className="mb-2 block">
                      <Label htmlFor="date" value="Expected date of arrival" />
                    </div>
                 <Datepicker name="date" id="date" minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1)} maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 6, new Date().getDate())} 
                  onSelectedDateChanged={handleDateChange}
                 />
                 {/* {selectedDate} */}
                  </div>
                  <div className="w-full mt-4">
                  </div>
                  <br/>
                  <TextInput
                      id="otp"
                      value={otpCode}
                      onChange={(e) => setOtpCode(e.target.value)}
                      type={otpSent ? "text" : "hidden"}
                      placeholder="Enter your OTP"
                      className={`transition duration-300 ${otpSent ? 'opacity-100' : 'opacity-0'}`}
                    />
                    {otpError && <div className="text-red-500">{otpError}</div>}
  {selectedPackage.priceWithoutFnB && <><Checkbox
                  id="without-fb"
                  className="mr-2"
                  checked={withoutFb}
                  onChange={(e) => setWithoutFb(e.target.checked)} /><label htmlFor="without-fb">Without F&B</label></>}
  <div>
</div>
                  <div className="w-full mt-4">
                   {otpVerified && <Button className="bg-[#FF8303]" type="submit">Submit</Button>}
                    {countdown === 0 ? (
                     <Button className="text-white bg-[#FF8303] hover:bg-[#FE6E00]" type="button" onClick={() => handleSendOTP(values.phone)}>
                     Send OTP
                   </Button>
                    ) : (
                      <>
                      <br/>
                      {!otpVerified && <Button type="button" className="text-white bg-[#FF8303] hover:bg-[#FE6E00] " onClick={() => handleVerifyOTP(values.phone, otpCode)}>
                          Verify OTP
                        </Button>}
                        {!otpVerified &&  <div>
                            OTP sent. Please check your phone.
                            <br />
                            resend OTP in {countdown}
                          </div> }</>
                    )}
                  </div>
                  <div className="flex flex-row" >

                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
        <Modal show={openModalConfirm} onClose={() => setOpenModalConfirm(false)}>
        <Modal.Header>Thank You</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              We have recieved your query. We will get back to you soon.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button onClick={ () => setOpenModalConfirm(false)} className="text-white bg-[#FE6E00]" >ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalBooking;

// import React, { useState } from 'react';
// import { Modal, ModalBody, ModalHeader, ModalFooter } from "flowbite-react";
// import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
// import { TextInput, Label, Button } from "flowbite-react";
// import { Datepicker } from "flowbite-react";
// import * as yup from 'yup';

// interface FormData1 {
//   mobileNumber: string;
// }
// interface FormData2 {
//   name: string;
//   selectedDate: string;
// }

// interface StepperProps {
//   open: boolean;
//   onClose: () => void;
// }

// const ModalBooking = ({ open, onClose }: StepperProps) => {
//   const [currentStep, setCurrentStep] = useState(1);
// //   const [formData1, setFormData1] = useState<FormData1>({ mobileNumber: ''});
// //   const [formData2, setFormData2] = useState<FormData2>({ name: '', selectedDate: ''});
//   const [sendOtp, setSendOtp] = useState(true);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const [isOtpVerified, setIsOtpVerified] = useState(false);
//   const [otpError, setOtpError] = useState('');
//   const [isMobileVerified, setIsMobileVerified] = useState(false);
//   const [userNumber, setUserNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [userName, setUserName] = useState('');
//   const [isUserNameValid, setIsUserNameValid] = useState(false);
//   const [selectedDate, setSelectedDate] = useState<Date | String>(new Date());
//   const [error, setError] = useState('');
//   const [nameError, setNameError] = useState('');

//   const handleFormSubmitStep1 = (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     const mobileNumber = (event.target as any).mobileNumber.value;
//     if (!validateMobile(mobileNumber)) {
//       return;
//     }
//     console.log("Form submitted step 1:", userNumber);
//     setCurrentStep(2);
//   };

//   const handleFormSubmitStep2 = (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     const name = (event.target as any).name.value;
//     if (!validateName(name)) {
//       return;
//     }
//     console.log("Form submitted step 2:", userName, selectedDate);
//     onClose();
//   };

//   const validateMobile = (value: string) => {
//     const formattedUserNumber = value.replace(/^0+/, '') // remove leading zeros
//       .replace(/^\+91/, '') // remove +91 at start
//       .replace(/\s+/g, '').replace(/\s-/g, ''); // remove spaces
//     setUserNumber(formattedUserNumber);
//     if (!/^\d{10}$/.test(formattedUserNumber)) {
//       setError('Invalid mobile number. Please enter a 10-digit number.');
//       return false;
//     }else{
//       setError('');
//       return true;
//     }
//   };
//   const validateOtp = (value: string) => {
//       setOtp(value);
//       if(value.length !== 4){
//           setOtpError('Invalid OTP');
//           return false;
//       }else if(value === ''){
//         setOtpError('Please enter OTP');
//         return false;
//   }else{
//     setOtpError('');
//     setIsOtpVerified(true);
//     return true;
//   }
// }

//   const validateName = async (value: string) => {
//     const formattedName = value.trim(); // remove leading and trailing spaces
//     setUserName(formattedName); // assuming you want to store the formatted name
//     if (!/^[a-zA-Z ]+$/.test(formattedName)) {
//       setNameError('Invalid Name');
//       return false; // indicate that the name is invalid
//     } else {
//       setNameError('');
//       setIsUserNameValid(true);
//       return true; // indicate that the name is valid
//     }
//   };

//   const handleSendOTP = () => {
//     if(!userNumber){
//       setError('Please enter your mobile number');
//       return false;
//     }
//     setSendOtp(false);
//     setIsOtpSent(true);
//     console.log("Sending OTP...");
//     console.log("OTP sent successfully to " + userNumber);
//     return true;
//   };

//   const handleNextStep = () => {
//     setCurrentStep(currentStep + 1);
//   };

//   const handleCloseModal = () => {
//     setCurrentStep(1);
//     onClose();
//   };

//   const renderStepContent = () => {
//     switch (currentStep) {
//       case 1:
//         return (
//           <form onSubmit={handleFormSubmitStep1}>
//           <Label htmlFor="mobileNumber" value="Mobile Number" />
//           <TextInput
//             id="mobileNumber"
//             name="mobileNumber"
//             type="tel"
//             placeholder="Your mobile number"
//             autoComplete="tel"
//             onChange={(e) => validateMobile(e.target.value)}
//             value={userNumber}
//             className="input-field"
//           />
//           {error && <div style={{ color: 'red' }}>{error}</div>}
//           {!sendOtp && (
//             <TextInput
//               id="otp"
//               name="otp"
//               type="text"
//               placeholder="Enter OTP"
//               autoComplete="none"
//               onChange={(e) => validateOtp(e.target.value)}
//               value={otp}
//               className="input-field mt-2"
//             />
//           )}
//           {otpError && <div style={{ color: 'red' }}>{otpError}</div>}
//           {sendOtp && (
//             <Button type="button" className="mt-4 bg-[#3f83f8] hover:bg-[#3f83f8]" onClick={handleSendOTP} disabled={isSubmitting}>
//               Send OTP
//             </Button>
//           )}
//           {isOtpSent && (
//             <Button type="submit" className="mt-4 bg-[#3f83f8] hover:bg-[#3f83f8]" disabled={isSubmitting}>
//               Verify OTP
//             </Button>
//           )}
//         </form>
//       );
//     case 2:
//       return (
//         <form onSubmit={handleFormSubmitStep2}>
//           <Label htmlFor="name" value="Your Name" />
//           <TextInput
//             id="name"
//             name="name"
//             placeholder="Your Name"
//             autoComplete="name"
//             onChange={(e) => validateName(e.target.value)}
//             value={userName}
//             className="input-field"
//           />
//           {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
//           <Label htmlFor="arrivalDate" value="Expected Date of Arrival (Optional)" />
//           <Datepicker
//             id="arrivalDate"
//             name="arrivalDate"
//             minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)}
//             maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 6, new Date().getDate())}
//             title="Select Date of Arrival"
//             onSelectedDateChanged={(date) => {
//               setSelectedDate(date);
//             }}
//           />
//           <Button type="submit" className="mt-4 bg-[#3f83f8] hover:bg-[#3f83f8]" disabled={isSubmitting}>
//             Submit
//           </Button>
//         </form>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Modal show={open} size="md" popup onClose={handleCloseModal}>
//       <ModalHeader>Booking Details</ModalHeader>
//       <ModalBody>
//         {renderStepContent()}
//       </ModalBody>
//       <ModalFooter className="justify-end">
//         <Button type="button" color="gray" onClick={handleCloseModal}>
//           Close
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };

// export default ModalBooking;