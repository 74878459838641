const packages1 = [
  {
    id: 1,
    name: "Basic",
    pfor:"1 person",
    price: 2599,
    priceWithoutFnB:1999,
    days: "1N/1D",
    category:"Single Day",
    selectedItem: "Ujjain to Omkareshwar, Basic single day package for 1 person.",
    includes:[
      "All the convenience by Two Wheeler or Car with a local companion",
      "Fully Furnished A/C Dormatory or a non A/C room", 
      "Pick-up and Drop-off", 
      "Ujjain Darshan Experience including 8 main Temples and kshipra aarti", 
      "Food and bevarages including Breakfast lunch and Dinner", 
      "Localite Companion to guide you through our miraculous holy City", 
      "Miraculous stories of our holy city"
    ],
    description: "Ideal for solo travelers seeking a fulfilling experience.",
    itinerary:["Welcome to Ujjain: Arrive at Indore Airport or Ujjain Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
              " Experience the grandeur of Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva. Marvel at the intricate architecture, vibrant atmosphere, and stunning sculptures.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
         ],
    amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M168-192q-70 0-119-49T0-360q0-64 41.5-112T146-526l-50-50H0v-48h156l132 106 144-58h155l-95-120H384v-72h142l109 137 133-89v144h-89l49 61q16-5 31.5-9t32.5-4q70 0 119 49t49 119q0 70-49 119t-119 49q-70 0-119-49t-49-119q0-32 12-61t33-53l-17-21-124 207H384l-50-50q-8 63-55.5 104.5T168-192Zm0-72q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm278-240-126 50 126-50h128-128Zm346 240q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm-305-96 87-144H446l-126 50 94 94h73Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
  },
  {
    id: 2,
    name: "Premium",
    pfor:"1 person",
    days: "1N/1D",
    category:"Single Day",
    selectedItem: "Ujjain to Omkareshwar, Premium single day package for 1 person ",
    price:4999,
    priceWithoutFnB:4099,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished A/C premium class stay.", 
      "Pick-up and Drop-off.", 
      "Ujjain Darshan Experience including 10 main Temple and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to guide you through our miraculous holy City", 
    ],
    description: "Elevate your Ujjain experience with premium accommodations.",
    itinerary:["Welcome to Ujjain: Arrive at Indore Airport or Ujjain Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious heavy breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
              "Experience the grandeur of Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva. Marvel at the intricate architecture, vibrant atmosphere, and stunning sculptures.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
           ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
  {
    id: 3,
    name: "Luxury",
    pfor:"1 person",
    category:"Single Day",
    selectedItem: " Ujjain to Omkareshwar, Luxury single day package for 1 person.",
    price:5999,
    days: "1N/1D",
    priceWithoutFnB:4899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    // if you have more time to explore we can visit omkareshwar
    description: "Immerse yourself in ultimate luxury and comfort.",
      itinerary:[
        "Welcome to Ujjain: Arrive at Indore Airport or Ujjain Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious heavy breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
              "Experience the grandeur of Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva. Marvel at the intricate architecture, vibrant atmosphere, and stunning sculptures.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
          ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
];
const packages2 = [
  {
    id: 1,
    name: "Basic",
    pfor:"2 People",
    price: 4499,
    days: "1N/1D",
    category:"Single Day",
    selectedItem: "Ujjain to Omkareshwar, Basic single day package for 2 persons",
    priceWithoutFnB:3899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    description: "Discover Ujjain's wonders together. Our Two-Person Package offers a personalized experience. ",
    itinerary:["Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
         ],
    amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M168-192q-70 0-119-49T0-360q0-64 41.5-112T146-526l-50-50H0v-48h156l132 106 144-58h155l-95-120H384v-72h142l109 137 133-89v144h-89l49 61q16-5 31.5-9t32.5-4q70 0 119 49t49 119q0 70-49 119t-119 49q-70 0-119-49t-49-119q0-32 12-61t33-53l-17-21-124 207H384l-50-50q-8 63-55.5 104.5T168-192Zm0-72q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm278-240-126 50 126-50h128-128Zm346 240q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm-305-96 87-144H446l-126 50 94 94h73Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
  },
  {
    id: 2,
    name: "Premium",
    pfor:"2 People",
    category:"Single Day",
    days: "1N/1D",
    selectedItem: "Ujjain to Omkareshwar, Premiun single day package for 2 persons",
    price:5099,
    priceWithoutFnB:4899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    description: "Elevate your Ujjain experience with luxurious accommodations.",
    itinerary:[
      "Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
    ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
  {
    id: 3,
    name: "Luxury",
    pfor:"2 People",
    days: "1N/1D",
    category:"Single Day",
    selectedItem: " Ujjain to Omkareshwar, Luxury single day package for 2 persons",
    price:7099,
    priceWithoutFnB:5899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    description: "Experience the finest Ujjain has to offer, from accommodations to activities.",
      itinerary:[
        "Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
          ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
];
const packages3 = [
  {
    id: 1,
    name: "Basic",
    pfor:"3 People",
    category:"Single Day",
    selectedItem: "Ujjain to Omkareshwar, Basic single day package for 3 person",
    price: 5999,
    
    priceWithoutFnB:4899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    days: "1N/1D",
    description: "Explore Ujjain's sacred sites and rich history as a trio. ",
    itinerary:["Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
         ],
    amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M168-192q-70 0-119-49T0-360q0-64 41.5-112T146-526l-50-50H0v-48h156l132 106 144-58h155l-95-120H384v-72h142l109 137 133-89v144h-89l49 61q16-5 31.5-9t32.5-4q70 0 119 49t49 119q0 70-49 119t-119 49q-70 0-119-49t-49-119q0-32 12-61t33-53l-17-21-124 207H384l-50-50q-8 63-55.5 104.5T168-192Zm0-72q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm278-240-126 50 126-50h128-128Zm346 240q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm-305-96 87-144H446l-126 50 94 94h73Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
  },
  {
    id: 2,
    name: "Premium",
    pfor:"3 People",
    category:"Single Day",
    days: "1N/1D",
    selectedItem: "Ujjain to Omkareshwar, Premium single day package for 3 persons",
    price:7499,
    priceWithoutFnB:5899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    description: "Indulge in personalized services and amenities, tailored for three.",
    itinerary:[
      "Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
               "Return to Rest: Return to your hotel or homestay for a restful night."
    ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
  {
    id: 3,
    name: "Luxury",
    pfor:"3 People",
    days: "1N/1D",
    category:"Single Day",
    selectedItem: "Ujjain to Omkareshwar, Luxury single day package for 3 persons",
    price:8499,
    priceWithoutFnB:6899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    description: "Create unforgettable memories with personalized VIP services designed for three.",
      itinerary:["Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."    ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
];
const packages4 = [
  {
    id: 1,
    name: "Basic",
    pfor:"4 People",
    category:"Single Day",
    selectedItem: "Ujjain to Omkareshwar, Basic single day package for 4 persons ",
    price: 7499,
    priceWithoutFnB:6899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    days: "1N/1D",
    description: "Enjoy a personalized experience tailored for your group. ",
    itinerary:["Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
         ],
    amenities: [
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M168-192q-70 0-119-49T0-360q0-64 41.5-112T146-526l-50-50H0v-48h156l132 106 144-58h155l-95-120H384v-72h142l109 137 133-89v144h-89l49 61q16-5 31.5-9t32.5-4q70 0 119 49t49 119q0 70-49 119t-119 49q-70 0-119-49t-49-119q0-32 12-61t33-53l-17-21-124 207H384l-50-50q-8 63-55.5 104.5T168-192Zm0-72q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm278-240-126 50 126-50h128-128Zm346 240q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm-305-96 87-144H446l-126 50 94 94h73Z"/></svg>`, name: "Pick-up, Drop-off" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
        { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
        ],
  },
  {
    id: 2,
    name: "Premium",
    pfor:"4 People",
    category:"Single Day",
    days: "1N/1D",
    selectedItem: "Ujjain to Omkareshwar, Premium single day package for 4 persons",
    price:8099,
    priceWithoutFnB:6899,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    description: "Indulge in personalized services and amenities, tailored for four.",
    itinerary:[
      "Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
      ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
  {
    id: 3,
    name: "Luxury",
    pfor:"4 People",
    category:"Single Day",
    days: "1N/1D",
    selectedItem: "Ujjain to Omkareshwar, Luxury single day package for 4 persons",
    price:10999,
    priceWithoutFnB:8999,
    includes:[
      "All the convenience by A/C Car with a local companion.",
      "Fully Furnished Luxury class stay.", 
      "Pick-up and Drop-off.", 
      "Spiritually guided Ujjain Darshan Experience including 12 main Temples and kshipra aarti.", 
      "Food and bevarages including Breakfast lunch and Dinner with some refreshment in between.", 
      "Localite Companion to take care of you at every step.",
      "Miraculous Stories of our Holy City", 
      "Unforgetable Wonderful Memories",
      "Insurance of Spiritual Vibe",
    ],
    description: "Create unforgettable memories with personalized VIP services designed for four.",
      itinerary:[
        "Welcome to Ujjain: Arrive at Ujjain Airport or Railway Station, where you'll be warmly greeted by your local companion.",
       "Comfortable Accommodation: Transfer to your chosen hotel or homestay, where you can relax and freshen up.",
        "Morning Delights: Enjoy a delicious breakfast to start your day.",
         "Spiritual Awakening: Embark on a guided tour of the renowned Mahakaleshwar Temple, one of the 12 Jyotirlingas of Lord Shiva.",
          "Cultural Exploration: Explore the bustling local markets, immersing yourself in the vibrant culture and traditions of Ujjain. ",
           "Lunch Break: Enjoy a flavorful lunch at a local restaurant.",
            "Sacred Sojourn: Continue your spiritual journey by visiting other significant temples in Ujjain, guided by your local companion.",
             "Evening Bliss: Witness the captivating Kshipra Aarti and explore the vibrant Mahakal Lok, a recently developed cultural complex dedicated to Lord Shiva.",
              "Dinner Delight: Savor a delicious dinner at a local restaurant.",
          "Return to Rest: Return to your hotel or homestay for a restful night."
          ],
    amenities: [
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M240-240v48q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-312l78-195q8-20 26-32.5t41-12.5h95v-72h192v72h95q22.42 0 40.71 12.5T738-699l78 195v312q0 10.2-6.9 17.1-6.9 6.9-17.1 6.9h-48q-10.2 0-17.1-6.9-6.9-6.9-6.9-17.1v-48H240Zm1-312h479l-48-120H289l-48 120Zm-25 72v168-168Zm96 132q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm336 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-432 36h528v-168H216v168Z"/></svg>`, name: "Pick-up, Drop-off" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M48-192v-576h72v384h312v-336h336q60 0 102 42t42 102v384h-72v-120H120v120H48Zm228-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm228 48h336v-192q0-29.7-21.15-50.85Q797.7-648 768-648H504v264ZM276-504q20.4 0 34.2-13.8Q324-531.6 324-552q0-20.4-13.8-34.2Q296.4-600 276-600q-20.4 0-34.2 13.8Q228-572.4 228-552q0 20.4 13.8 34.2Q255.6-504 276-504Zm0-51Zm228-93v264-264Z"/></svg>`, name: "Stay" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M300-96v-389q-47-12-77.5-50.1T192-624v-240h72v240h36v-240h72v240h36v-240h72v240q0 50.8-30.5 88.9Q419-497 372-485v389h-72Zm396 0v-336H576v-240q0-79.68 56.16-135.84T768-864v768h-72Z"/></svg>`, name: "Food" },
      { icon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M96-96v-432h72v96h72l144-384v-96h72v96h48v-96h72v96l144 384h72v-96h72v432H528v-192h-96v192H96Zm248-408h272l-36-96H380l-36 96Zm63-168h146l-27-72h-92l-27 72ZM168-168h192v-192h240v192h192v-192H670l-27-72H317l-27 72H168v192Zm312-293Z"/></svg>`, name: "Temple Visit" },
      ],
  },
];
export {packages1, packages2, packages3, packages4}