// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useEffect, useRef } from 'react';
// gsap.registerPlugin(ScrollTrigger);
// export default function WhyUs() { 
//     const whyUsRef = useRef(null);

//     useEffect(() => {
//       // IntersectionObserver options
//       const options = {
//         root: null, // Observe within the viewport
//         threshold: 0.2, // Trigger animation when 50% or more is visible
//       };
  
//       // Create IntersectionObserver
//       const observer = new IntersectionObserver(entries => {
//         entries.forEach(entry => {
//           if (entry.isIntersecting) {
//             animateFeaturesIn(whyUsRef.current);
//             observer.unobserve(entry.target); // Unobserve after animation
//           }
//         });
//       }, options);
  
//       // Observe the features section
//       if (whyUsRef.current) {
//         observer.observe(whyUsRef.current);
//       }
  
//       return () => {
//         // Clean up observer on unmount
//         if (observer && whyUsRef.current) {
//           observer.unobserve(whyUsRef.current);
//         }
//       };
//     }, []);
  
//     const animateFeaturesIn = (features: any ) => {
//       // Animate individual features with stagger and custom timings
//       gsap.fromTo(
//         features.querySelectorAll('.animationInfo'),
//         { opacity: 0, y: 20 }, // Initial state
//         {
//           opacity: 1,
//           y: 0,
//           stagger: 0.2, // Stagger animation between elements by 0.2 seconds
//           duration: 1, // Individual animation duration of 0.8 seconds
//           ease: 'power3.out' // Easing function for smooth animation
//         }
//       );
//     };

//   return (
//     <div ref={whyUsRef} className="mx-auto w-full p-12 bg-[#F9F9F6] mt-10 rounded-lg dark:bg-[#313d4d] dark:text-white why-us " >
//       <h2 className="text-3xl text-center animationInfo opacity-0 font-bold mb-4">Why Choose Us</h2>
// {/* <div className="content flex flex-wrap -mx-4 md:p-12 sm:p-0 bg-white rounded-lg dark:bg-[#313d4d] dark:text-white"> */}
//   <ul className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3;
//   gap-4; mb-4">
//     <li className="animationInfo opacity-0">
//           <div className="flex space-x-4">
//             <span className="inline-block bg-gray-200 rounded-full p-2 dark:bg-gray-700">✔</span>
//             <p className="text-lg text-gray-600 dark:text-white">
//               Get assurance of a tension-free start to your trip...
//             </p>
//           </div>
//         </li>
//     {/* <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> Get Crash course of 20 years of Ujjain Experience.
//     </li> */}
//     <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> We save your precious time of hagglings with troublesome local vendors by providing free pickup - drops from Ujjain Junction railway station.
//     </li>
//     <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> Because we provide most Qualitised Spiritual Services along with lowest rates in Ujjain, which is like finding a needle dropped in a storm.
//     </li>
//     <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> Ensure your comfort , safety and homely feel throughout the trip . Whether it be haggling with securities inside the temple , we provide a personal localite companion to take care of you in every Temple of Ujjain.
//     </li>
//     <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> Because we know beside getting pushed again and again inside the temples , there's a whole universe waiting to talk to you in our Holy City.
//     </li>
//     <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> As We provide you with a responsible localite companion who let you know the significance and importance of Ujjain through Miraculous Stories. This service of ours is unmatchable
//     </li>
//     <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> We focus on assuring your comfortable stay & logistics , comfortable free pickup - drops , Food & beverages and lastly best memories .
//     </li>
//     <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
//       <span className="mr-2">•</span> Assurance of safety and sanity.
//     </li>
//   </ul>
//   {/* <p className='text-lg animationInfo opacity-0 text-justify font-medium leading-relaxed text-gray-600 dark:text-white'>
//   Experience a hassle-free Ujjain pilgrimage with Ujjain on Wheels. Our expert guidance ensures a comfortable and memorable journey, from personalized packages and free transportation to expert local companionship and spiritual insights. We prioritize your comfort, safety, and the opportunity to connect with the divine in this ancient city. Book your Ujjain darshan now and embark on a transformative experience.
//   </p> */}
// {/* </div> */}
//     </div>
//   );
// }

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);
export default function WhyUs() { 
    const whyUsRef = useRef(null);

    useEffect(() => {
      // IntersectionObserver options
      const options = {
        root: null, // Observe within the viewport
        threshold: 0.2, // Trigger animation when 50% or more is visible
      };
  
      // Create IntersectionObserver
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateFeaturesIn(whyUsRef.current);
            observer.unobserve(entry.target); // Unobserve after animation
          }
        });
      }, options);
  
      // Observe the features section
      if (whyUsRef.current) {
        observer.observe(whyUsRef.current);
      }
  
      return () => {
        // Clean up observer on unmount
        if (observer && whyUsRef.current) {
          observer.unobserve(whyUsRef.current);
        }
      };
    }, []);
  
    const animateFeaturesIn = (features: any ) => {
      // Animate individual features with stagger and custom timings
      gsap.fromTo(
        features.querySelectorAll('.animationInfo'),
        { opacity: 0, y: 20 }, // Initial state
        {
          opacity: 1,
          y: 0,
          stagger: 0.2, // Stagger animation between elements by 0.2 seconds
          duration: 1, // Individual animation duration of 0.8 seconds
          ease: 'power3.out' // Easing function for smooth animation
        }
      );
    };

  return (
    <div ref={whyUsRef} className="mx-auto w-full p-12 bg-[#F9F9F6] mt-10 rounded-lg dark:bg-[#313d4d] dark:text-white why-us " >
      <h2 className="text-3xl text-center animationInfo opacity-0 font-bold mb-4 bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] text-transparent bg-clip-text bodoni-libre">Why Choose Us</h2>
{/* <div className="content flex flex-wrap -mx-4 md:p-12 sm:p-0 bg-white rounded-lg dark:bg-[#313d4d] dark:text-white"> */}
  <ul className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3;
  gap-4; mb-4">
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white ">
            Get assurance of tension free start to your trip before reaching Ujjain Junction Railway Station or Indore Airport by giving us a call and get your customised Spiritual Package secured to take care of your Trip.
            </p>
          </div>
        </li>
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block  ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white">
            Because we provide most Qualitised Spiritual Services along with lowest rates in Ujjain, which is like finding a needle dropped in a storm.
            </p>
          </div>
        </li>
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white">
            As We provide you with a responsible localite companion who let you know the significance and importance of Ujjain through Miraculous Stories. This service of ours is unmatchable
            </p>
          </div>
        </li>
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block  ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white">
            To save yourself from misconceptions and misguided wrong entries. Here we save your time and peace of mind
        </p>
          </div>
        </li>
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block  ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white">
            We save your precious time of hagglings with troublesome local vendors by providing free pickup - drops from Ujjain Junction railway station.
            </p>
          </div>
        </li>
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white">
            Because we know beside getting pushed again and again inside the temples , there's a whole universe waiting to talk to you in our Holy City.
            </p>
          </div>
        </li>
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block  ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white">
            We ensure your comfort , safety and homely feel throughout the trip . Whether it be haggling with securities inside the temple , we provide a personal localite companion to take care of you in every Temple of Ujjain.
            </p>
          </div>
        </li>
    <li className="animationInfo opacity-0 mb-2">
          <div className="flex space-x-4">
            <span className="inline-block  dark:bg-gray-700 ">✔</span>
            <p className="text-lg text-gray-600 dark:text-white">
            We focus on assuring your comfortable stay & logistics , comfortable free pickup - drops , Food & beverages and lastly best memories .
            </p>
          </div>
        </li>
    {/* <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
      <span className="mr-2">•</span> Get Crash course of 20 years of Ujjain Experience.
    </li> */}
   
    {/* <li className="text-lg animationInfo opacity-0 font-medium leading-relaxed text-gray-600 dark:text-white">
      <span className="mr-2">•</span> Assurance of safety and sanity.
    </li> */}
  </ul>
  {/* <p className='text-lg animationInfo opacity-0 text-justify font-medium leading-relaxed text-gray-600 dark:text-white'>
  Experience a hassle-free Ujjain pilgrimage with Ujjain on Wheels. Our expert guidance ensures a comfortable and memorable journey, from personalized packages and free transportation to expert local companionship and spiritual insights. We prioritize your comfort, safety, and the opportunity to connect with the divine in this ancient city. Book your Ujjain darshan now and embark on a transformative experience.
  </p> */}
{/* </div> */}
    </div>
  );
}