
import { Footer } from "flowbite-react";
import { Link } from "react-router-dom";
export default function Component() {
  return (
    <footer className="bg-transparent  text-gray-600 py-12">
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap justify-between">
      <div className="w-full   md:w-1/2 xl:w-1/3 mb-4 md:mb-0">
        <img src="ujjainonwheels.png" alt="Logo" className="h-16 mb-4" />
        <p className="text-xl md:text-base mb-2 gap-2 flex items-center"><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M480-307q96-79 144-151.5t48-135.1q0-86.68-53.5-142.54Q565-792 480-792t-138.5 56Q288-680 288-594.06 288-532 336-460t144 153Zm0 91Q346-317 281-410.61q-65-93.6-65-183.39 0-118.01 73.56-194 73.56-76 190.5-76t190.44 76Q744-712.01 744-594q0 89.79-65 183.39Q614-317 480-216Zm.21-312Q510-528 531-549.21t21-51Q552-630 530.79-651t-51-21Q450-672 429-650.79t-21 51Q408-570 429.21-549t51 21ZM216-96v-72h528v72H216Zm264-504Z"/></svg> C-41/21, Rishi Nagar, Ujjain</p>
        <p className="text-xl md:text-base mb-2 gap-2 flex items-center cursor-pointer" onClick={() => {
    window.location.href = `tel:${"9977886853"}`;
}}>
    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M763-145q-121-9-229.5-59.5T339-341q-86-86-135.5-194T144-764q-2-21 12.29-36.5Q170.57-816 192-816h136q17 0 29.5 10.5T374-779l24 106q2 13-1.5 25T385-628l-97 98q20 38 46 73t57.97 65.98Q422-361 456-335.5q34 25.5 72 45.5l99-96q8-8 20-11.5t25-1.5l107 23q17 5 27 17.5t10 29.5v136q0 21.43-16 35.71Q784-143 763-145ZM255-600l70-70-17.16-74H218q5 38 14 73.5t23 70.5Zm344 344q35.1 14.24 71.55 22.62Q707-225 744-220v-90l-75-16-70 70ZM255-600Zm344 344Z"/></svg>
    9977886853
</p>
        <p className="text-xl md:text-base gap-2 flex items-center" ><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm312-240L168-611v347h624v-347L480-432Zm0-85 312-179H168l312 179Zm-312-94v-85 432-347Z"/></svg><a href="mailto:care@ujjainonwheels.in">care@ujjainonwheels.in</a></p>
      </div>
      <div className="w-full md:w-1/2 xl:w-1/3 mb-4 md:mb-0">
        <h6 className="text-2xl md:text-base font-bold mb-2 bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] inline-block text-transparent bg-clip-text bodoni-libre">Follow Us</h6>
        <ul className="list-none mb-0 flex gap-4">
          <li className="mb-2">
            <a href="#" className="text-gray-600 text-xl md:text-base hover:text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"   viewBox="0 0 16 16" id="facebook">
  <path fill="#1976D2" d="M14 0H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2z"></path>
  <path fill="#FAFAFA" fill-rule="evenodd" d="M13.5 8H11V6c0-.552.448-.5 1-.5h1V3h-2a3 3 0 0 0-3 3v2H6v2.5h2V16h3v-5.5h1.5l1-2.5z" clip-rule="evenodd"></path>
</svg>
            </a>
          </li>
          <li className="mb-2">
            <a href="#" className="text-gray-600 text-xl md:text-base hover:text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 512 512" id="twitter">
  <g clip-path="url(#clip0_84_15697)">
    <rect width="512" height="512" fill="#000" rx="60"></rect>
    <path fill="#fff" d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"></path>
  </g>
  <defs>
    <clipPath id="clip0_84_15697">
      <rect width="512" height="512" fill="#fff"></rect>
    </clipPath>
  </defs>
</svg>
            </a>
          </li>
          <li className="mb-2">
            <a href="#" className="text-gray-600 text-xl  p-0 hover:text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="24" height="24" id="instagram">
  <linearGradient id="a" x1="1.464" x2="14.536" y1="14.536" y2="1.464" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#FFC107"></stop>
    <stop offset=".507" stop-color="#F44336"></stop>
    <stop offset=".99" stop-color="#9C27B0"></stop>
  </linearGradient>
  <path fill="url(#a)" d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"></path>
  <linearGradient id="b" x1="5.172" x2="10.828" y1="10.828" y2="5.172" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#FFC107"></stop>
    <stop offset=".507" stop-color="#F44336"></stop>
    <stop offset=".99" stop-color="#9C27B0"></stop>
  </linearGradient>
  <path fill="url(#b)" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"></path>
  <linearGradient id="c" x1="11.923" x2="12.677" y1="4.077" y2="3.323" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#FFC107"></stop>
    <stop offset=".507" stop-color="#F44336"></stop>
    <stop offset=".99" stop-color="#9C27B0"></stop>
  </linearGradient>
  <circle cx="12.3" cy="3.7" r=".533" fill="url(#c)"></circle>
</svg>
            </a>
          </li>
        </ul>
      </div>
      <div className="w-full md:w-1/2 xl:w-1/3 mb-4 md:mb-0">
        <h6 className="text-2xl md:text-base font-bold mb-2 bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] inline-block text-transparent bg-clip-text bodoni-libre">Legal</h6>
        <ul className="list-none mb-0">
          <li className="mb-2">
            <Link to="/terms" className="text-gray-600 md:text-base text-xl hover:text-gray-900 ">Terms and Conditions</Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Footer.Divider />
        <div className="w-full flex items-center justify-center">
          <Footer.Copyright href="https://ujjainonwheels.in" by="Ujjainonwheels" year={2024} />
        </div>
</footer>
  );
}
