export default function About() {
  return (
    <div className=" mx-auto p-4 md:p-6 lg:p-8 bg-[#F9F9F6] rounded-lg shadow-md dark:bg-[#313d4d]">
        <h2 className="text-3xl font-bold text-center mb-4 dark:text-gray-400 bg-gradient-to-tr from-[#FE6E00] to-[#FFAF42] text-transparent bg-clip-text bodoni-libre">About Us</h2>
      <p className="text-lg leading-relaxed text-gray-600 dark:text-gray-400 text-justify">
        We provide you with every accessible services required to ensure your Ujjain Darshan Experience Elegant and Memorable. 
        Our motive is to make you meet the Real Divine Forms of the Lord rather than just seeing the Statue or Idols of the God through practical logics and stories . Ujjain on Wheels makes you explore the true heritage culture of Holy and historic Ujjain with our local insights and experience. Alongside our homely feel , We help you to restore your relationship with the Lord in one of the Oldest city existing . 
        Our services ensure your comfort and safety throughout the trip and saves your time from hagglings with troublesome vendors and auto rickshaw drivers .
        We provide a Personal Localite Companion to take care of You in every of our Ujjain Packages.
        Ujjain on Wheels only provide and promote cut to cut original knowledge with reference to Vedic Scriptures and Our Guru Parampara's Teachings. 
      </p>
    </div>
  )
} 